import * as actions from "./ArticlesActions";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PublicGrid from "../../components/ux/PublicGrid/PublicGrid";
import {ReactComponent as NewIcon} from "../../assets/icons/new_icon.svg";
import { getYear } from 'date-fns';

const ArticlesPublic = () => {
    const [articles, setArticles] = useState([])
    const [year, setYear] = useState(getYear(new Date()))
    const { user_id } = useParams();
    const reload = () => {
        const params = {
            year,
            user_id
        }
        actions.loadPublicArticles(params, setArticles)
    }

    useEffect(() => {
        reload()
    }, [year]);

    return (
        <div className="articles-public">
            <PublicGrid
                data={articles}
                icon={NewIcon}
                name="Articles"
                year={year}
                onYearChange={setYear}
                variant="articles"
            />
        </div>
    )
}

export default ArticlesPublic