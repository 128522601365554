import { useEffect } from "react";
import Button from "../Button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "../Modal/Modal";
import DateInput from "../../form/DateInput/DateInput";
import './ModalFilter.scss'
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter_icon.svg";

const dateSchema = yup.object().shape({
    from: yup.date().typeError("Invalid date format").required("Date is required"),
    to: yup.date()
        .typeError("Invalid date format")
        .required("Date is required")
        .min(
            yup.ref('from'),
            "End date must be later than start date"
        ),
});

const ModalFilter = ({ onClose, onConfirm, name, item, initialData }) => {
    const { setValues, values, handleSubmit, errors, touched, handleChange, setFieldValue } = useFormik({
        initialValues: {
            from: "",
            to: ""
        },
        validationSchema: dateSchema,
        onSubmit: (values) => {
            const dateData = {
                from: values.from,
                to: values.to,
            };
            onConfirm(dateData);
        },
    });

    useEffect(() => {
        if (initialData && initialData.from && initialData.to) {
            setValues({
                from: initialData.from,
                to: initialData.to
            });
        }
    }, [initialData, setValues]);

    return (
        <div className="modal-filter">
            <Modal
                onClose={onClose}
                name={name}
                item={item}
                icon={FilterIcon}
            >
                <form onSubmit={handleSubmit}>
                    <DateInput
                        id="from"
                        name="from"
                        required
                        label="From"
                        errorMessage={errors.from}
                        touched={touched.from}
                        value={initialData?.from}
                        onChange={(value) => setFieldValue("from", value)}
                        type="date"
                    />
                    <DateInput
                        id="to"
                        name="to"
                        required
                        label="To"
                        errorMessage={errors.to}
                        touched={touched.to}
                        value={initialData?.to}
                        onChange={(value) => setFieldValue("to", value)}
                        type="date"
                    />

                    <div className="actions">
                        <Button type="button" onClick={onClose} label="Cancel" variant="cancel" />
                        <Button type="submit" label="Save" />
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default ModalFilter;
