import Modal from "../Modal/Modal";
import Button from '../Button/Button';

const ConfirmModal = ({ onClose, name, item, children }) => {
    return (
        <Modal
            onClose={onClose}
            name={name}
            item={item}
            buttons={
                <Button onClick={onClose} label="Close" variant="cancel" />
            }
        >
            {children}
        </Modal>
    );
};

export default ConfirmModal;
