import RecipesPieChart from "../../components/ux/./RecipesPieChart/RecipesPieChart";
import React, { useEffect, useMemo, useState } from "react";
import * as actions from './ReportsActions.js';
import TableForm from "../../components/form/TableForm/TableForm";
import "./Reports.scss";
import RecipesBarChart from "../../components/RecipesBarChart/RecipesBarChart";
import ModalFilter from "../../components/ux/ModalFilter/ModalFilter";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart_icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter_icon.svg";
import Button from "../../components/ux/Button/Button";
import Icon from "../../assets/Icon";
import {sumBy, uniqBy} from "lodash";
import {startOfYear, endOfYear, format} from 'date-fns';

const COLORS = ["#ea4333", "#fd8e00", "#ffc452", "#8cbf5b", "#89e462", "#78ccff", "#3241ad", "#897cfe", "#d8b5ff", "#ffaaa3"];
const DEFAULT_DATE = {
    from: format(startOfYear(new Date()), 'yyyy/MM/dd'),
    to: format(endOfYear(new Date()), 'yyyy/MM/dd')
};

const Reports = () => {
    const [reportData, setReportData] = useState({categories: []});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [filters, setFilters] = useState();

    useEffect(() => {
        reload();
    }, [filters]);

    const reload = () => {
        const params = {
            start_date: filters?.from || DEFAULT_DATE.from,
            end_date: filters?.to || DEFAULT_DATE.to
        };

        actions.categoryUsage(params, (data) => {
            const total_recipes = sumBy(data, 'value');
            const categories = data.map((category, index) => ({
                ...category,
                percent: `${((category.value / total_recipes) * 100).toFixed(1)}%`,
                color: COLORS[index % COLORS.length]
            }));

            setReportData({ categories, total_recipes });
        });
    };

    const columns = useMemo(() => [
        { Header: "Color", accessor: "color", Cell: ({ value }) => (
                <div style={{ backgroundColor: value }} className="colors" />
            ) },
        { Header: "Ingredient", accessor: "name" },
        { Header: "Amount", accessor: "value" },
        { Header: "Percent", accessor: "percent" },
    ], [reportData.categories]);

    const uniqueData = uniqBy(reportData.categories, 'id');

    return (
        <div className="reports">
            <header>
                <span><Icon icon={ChartIcon} /><b>Reports</b></span>
                <Button label="Filters" onClick={() => setIsOpenModal(true)} type="button" icon={FilterIcon}/>
            </header>
            <RecipesPieChart
                data={uniqueData}
                label="Recipes"
                totalValue={reportData.total_recipes}
                colors={COLORS}
            />
            <div>
                <TableForm
                    data={uniqueData}
                    columns={columns}
                />
            </div>
            <RecipesBarChart
                data={reportData.categories}
                totalValue={reportData.total_recipes}
                filters={filters || DEFAULT_DATE}
            />
            {isOpenModal && <ModalFilter
                name="Filters"
                onClose={() => setIsOpenModal(false)}
                onConfirm={date => {
                    setFilters(date);
                    setIsOpenModal(false);
                }}
                initialData={filters}
            />}
        </div>
    );
};

export default Reports;
