import apiClient from '../../utils/apiClient';

export const loadSubpages = (params, callback) => {
    apiClient.get('/subpages', { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};
export const loadSubpage = (id, callback) => {
    apiClient.get(`/subpages/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteSubpage = (id, callback) => {
    apiClient.delete(`/subpages/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveSubpage = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/subpages/${id}` : `/subpages`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(response => callback(response))
        .catch(error => console.error(error));
};

export const loadPublicSubpage = (params, callback) => {
    apiClient.get(`/subpages/public_show`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};