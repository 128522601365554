import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';
import "./Editor.scss";
import { ReactComponent as UndoIcon } from "../../../assets/icons/undo_icon.svg";
import { ReactComponent as RedoIcon } from "../../../assets/icons/redo_icon.svg";
import Icon from "../../../assets/Icon";
import ReactDOMServer from 'react-dom/server';
import quillEmoji from "react-quill-emoji";
import "react-quill-emoji/dist/quill-emoji.css";
import classNames from "classnames";

function undoChange() {
    this.quill.history.undo();
}
function redoChange() {
    this.quill.history.redo();
}

const Quill = ReactQuill.Quill;

let BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register(
    {
        "formats/emoji": quillEmoji.EmojiBlot,
        "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
        "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
        "modules/emoji-shortname": quillEmoji.ShortNameEmoji
    },
    true
);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["12px", "14px", "16px", "18px", "20px", "24px", "32px"];
Quill.register(Size, true);

let icons = Quill.import("ui/icons");
icons["undo"] = `${ReactDOMServer.renderToStaticMarkup(<Icon icon={UndoIcon} />)}`;
icons["redo"] = `${ReactDOMServer.renderToStaticMarkup(<Icon icon={RedoIcon} />)}`;

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', { 'background': [] }, { 'color': [] }, { 'script': 'super' }, { 'script': 'sub' }],
            [{ header: [1, 2, 3, false] }, { 'size': Size.whitelist }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { indent: "-1" }, { indent: "+1" }, { 'align': [] }],
            ['link', 'image'],
            ['emoji'],
            ['undo', 'redo']
        ],
        handlers: {
            undo: undoChange,
            redo: redoChange,
        }
    },
    imageResize: true,
    'emoji-toolbar': true,
    'emoji-shortname': true,
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    }
};

const Editor = ({ label, required, value, onChange, error }) => {
    const [editorContent, setEditorContent] = useState(value || '');
    const quillRef = useRef(null);

    useEffect(() => {
        setEditorContent(value || '');
    }, [value]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.root.setAttribute('data-placeholder', error || '');
        }
    }, [error]);

    const handleChange = (content) => {
        setEditorContent(content);
        if (onChange) {
            onChange(content);
        }
    };

    return (
        <div className="editor">
            {label && <label className="label">
                {required && <span className="required">*</span>}
                {label}
            </label>}
            <ReactQuill
                ref={quillRef}
                value={editorContent}
                onChange={handleChange}
                modules={modules}
                className={classNames('editor-class', {
                    'error': error
                })}
                theme="snow"
            />
        </div>
    );
};

export default Editor;
