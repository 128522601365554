import './Form.scss';
import classNames from 'classnames';
import Button from '../Button/Button';
import Icon from "../../../assets/Icon";

const Form = ({ children, name, nameBold, buttonOnClick, buttonLabel, buttonType, icon, onSubmit, isFooterButton }) => {
    return (
        <form className={classNames('form', { 'footer-button': isFooterButton })} onSubmit={onSubmit}>
            <header>
                <span><Icon icon={icon}/> {name} <b>{nameBold}</b></span>
                {!isFooterButton && <Button label={buttonLabel} onClick={buttonOnClick} type={buttonType} />}
            </header>
            <div className="content">
                {children}
            </div>
            <footer>
                {isFooterButton && <Button label={buttonLabel} onClick={buttonOnClick} type={buttonType} />}
            </footer>
        </form>
    );
};

export default Form;
