import Card from "../Card/Card";
import "./PublicGrid.scss";
import Icon from "../../../assets/Icon";
import {useNavigate} from "react-router-dom";

const PublicGrid = ({ data, name, icon, onYearChange, year, variant }) => {
    const navigate = useNavigate()
    const handleYearChange = (direction) => {
        onYearChange(year + direction);
    };

    return (
        <div className="public-grid">
            <header>
                <span><Icon icon={icon}/><b>All {name}</b></span>
            </header>
            <div className="content">
                <div className="date">{name} from <b>{year}</b></div>
                <div className="cards">
                    {variant === 'recipes' && data.map((item, index) => (
                        <Card
                            key={item.id}
                            name={item.name}
                            prepTime={item.prep_time}
                            tags={item.tags}
                            imageUrl={item.image?.url}
                            className={index % 4 === 1 && "highlight"}
                            onClick={() => navigate(`${item.id}`)}
                        />
                    ))}
                    {variant === 'articles' && data.map((item, index) => (
                        <Card
                            key={item.id}
                            name={item.name}
                            imageUrl={item.image?.url}
                            className={index % 4 === 1 && "highlight"}
                            onClick={() => navigate(`${item.id}`)}
                        />
                    ))}
                </div>
            </div>
            <footer>
                <button onClick={() => handleYearChange(1)}>{year + 1}</button>
                <button onClick={() => handleYearChange(-1)}>{year - 1}</button>
            </footer>
        </div>
    );
};

export default PublicGrid;
