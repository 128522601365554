import React from 'react';
import "./Button.scss";
import Icon from "../../../assets/Icon";

const Button = ({ label, onClick, type = 'button', variant = 'primary', icon }) => {

  return (
      <button
          className={`button ${variant}`}
          onClick={onClick}
          type={type}
      >
        {icon && <Icon icon={icon} />}
        {label}
      </button>
  );
};

export default Button;
