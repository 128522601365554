import React, { useState, useEffect } from 'react';
import DropArea from "../../components/ux/DropArea/DropArea";
import DraggableItem from "../../components/ux/DraggableItem/DraggableItem";
import * as actions from "./SubpagesActions";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import { ReactComponent as SubpageIcon } from '../../assets/icons/subpage_icon.svg';
import { DragDropContext } from 'react-beautiful-dnd';
import {usePagination} from "../../hooks/usePagination";

const Subpages = () => {
    const [subpages, setSubpages] = useState([]);
    const [subpageToDelete, setSubpageToDelete] = useState(null);
    const navigate = useNavigate();

    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();

    useEffect(() => {
        reload();
    }, [page]);

    const reload = () => {
        const params = {
            page,
            per_page: perPage
        };
        actions.loadSubpages(params, data => {
            setSubpages(data.subpages);
            updatePagination(data.pagination)
        });
    };

    const moveItem = (sourceIndex, destinationIndex) => {
        const reorderedItems = Array.from(subpages);
        const [movedItem] = reorderedItems.splice(sourceIndex, 1);
        reorderedItems.splice(destinationIndex, 0, movedItem);

        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            position: index
        }));

        setSubpages(updatedItems);

        updatedItems.forEach(subpage => {
            actions.saveSubpage(subpage.id, subpage, () => null);
        });
    };

    const handleDelete = () => {
        if (subpageToDelete) {
            actions.deleteSubpage(subpageToDelete.id, () => {
                setSubpages(subpages.filter(subpage => subpage.id !== subpageToDelete.id));
                setSubpageToDelete(null);
            });
        }
    };

    const handleAdd = () => {
        navigate('/subpage/');
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }
        moveItem(source.index, destination.index);
    };

    return (
        <div className="subpages">
            <DragDropContext onDragEnd={onDragEnd}>
                <DropArea
                    icon={SubpageIcon}
                    nameBold="Main navigation"
                    buttonLabel="+ Add New"
                    buttonOnClick={handleAdd}
                    droppableId="droppable"
                    pagination={pagination}
                    onPaginationChange={onPaginationChange}
                >
                    {subpages.map((item, index) => (
                        <DraggableItem
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            index={index}
                            setItemToDelete={setSubpageToDelete}
                        />
                    ))}
                </DropArea>
            </DragDropContext>
            {subpageToDelete && (
                <DeleteModal
                    onClose={() => setSubpageToDelete(null)}
                    onConfirm={handleDelete}
                    name="Subpage > "
                    item={subpageToDelete.name}
                />
            )}
        </div>
    );
};

export default Subpages;
