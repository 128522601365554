import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Categories from './pages/categories/Categories';
import Category from './pages/categories/Category';
import Tags from './pages/tags/Tags';
import Tag from './pages/tags/Tag';
import Ingredients from './pages/ingredients/Ingredients';
import Ingredient from './pages/ingredients/Ingredient';
import Recipes from './pages/recipes/Recipes';
import Recipe from './pages/recipes/Recipe';
import Login from "./pages/login/Login";
import Users from "./pages/users/Users";
import User from "./pages/users/User";
import Layout from "./components/Layout/Layout";
import Search from "./pages/search/Search";
import Reports from "./pages/reports/Reports";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import Subpages from "./pages/subpages/Subpages";
import Subpage from "./pages/subpages/Subpage";
import RecipesPublic from "./pages/recipes/RecipesPublic";
import ArticlesPublic from "./pages/articles/ArticlesPublic";
import RecipePublic from "./pages/recipes/RecipePublic";
import ArticlePublic from "./pages/articles/ArticlePublic";
import SubpagePublic from "./pages/subpages/SubpagePublic";

function App() {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem("USER_PROFILE"))

    useEffect(() => {
    }, [navigate]);

    return (
        <Routes>
            <Route path="/" element={<Login />} />

            {user?.role === "USER" && (
                <Route element={<Layout variant="user"/>}>
                    <Route path="categories" element={<Categories />} />
                    <Route path="category/:id?" element={<Category />} />
                    <Route path="tags" element={<Tags />} />
                    <Route path="tag/:id?" element={<Tag />} />
                    <Route path="ingredients" element={<Ingredients />} />
                    <Route path="ingredient/:id?" element={<Ingredient />} />
                    <Route path="recipes" element={<Recipes />} />
                    <Route path="recipe/:id?" element={<Recipe />} />
                    <Route path="search/:model/:query" element={<Search />} />
                    <Route path="reports" element={<Reports />} />
                    <Route path="articles" element={<Articles />} />
                    <Route path="article/:id?" element={<Article />} />
                    <Route path="subpages" element={<Subpages />} />
                    <Route path="subpage/:id?" element={<Subpage />} />
                </Route>
            )}
            {user?.role === "ADMIN" && (
                <Route element={<Layout variant="admin"/>}>
                    <Route path="users" element={<Users />} />
                    <Route path="user/:id?" element={<User />} />
                </Route>
            )}
            <Route element={<Layout variant="guest"/>}>
                <Route path="public/:user_id/recipes/" element={<RecipesPublic />} />
                <Route path="public/:user_id/recipes/:id" element={<RecipePublic />} />
                <Route path="public/:user_id/articles/" element={<ArticlesPublic />} />
                <Route path="public/:user_id/articles/:id" element={<ArticlePublic />} />
                <Route path="public/:user_id/:subpage/" element={<SubpagePublic />} />
            </Route>
        </Routes>
    );
}

export default App;
