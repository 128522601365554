import { useEffect, useState } from "react";
import * as actions from './CategoriesActions.js';
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/icons/default_avatar_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as CategoryIcon } from "../../assets/icons/category_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import ConfirmModal from "../../components/ux/ModalConfirm/ConfirmModal";
import {usePagination} from "../../hooks/usePagination";
import {useSorting} from "../../hooks/useSorting";

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [categoryIsUsed, setCategoryIsUsed] = useState(false);
    const navigate = useNavigate();

    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();


    useEffect(() => {
        reload();
    }, [page, sorting]);

    const reload = () => {
        const filters = {
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        }
        actions.loadCategories(filters, data => {
            setCategories(data.categories);
            updatePagination(data.pagination);
        });
    };

    const handleCheckCategory = (category) => {
        if (category) {
            actions.checkCategoryUsage(category.id, (categoryUsed) => {
                setCategoryIsUsed(categoryUsed);
                setCategoryToDelete(category);
            });
        }
    };

    const handleDelete = () => {
        if (categoryToDelete && !categoryIsUsed) {
            actions.deleteCategory(categoryToDelete.id, () => {
                setCategories(prevCategories => prevCategories.filter(category => category.id !== categoryToDelete.id));
                setCategoryToDelete(null);
            });
        }
    };

    const handleAdd = () => {
        navigate('/category/');
    };

    const columns = [
        {
            Header: 'Category',
            accessor: 'name',
            Cell: ({ row }) => {
                const imageUrl = row.original.image?.url;

                return (
                    <div className="cell">
                        {imageUrl ?
                            <img src={imageUrl} alt={row.original.name} />
                            :
                            <Icon icon={IconAvatar} />
                        }
                        <span>{row.original.name}</span>
                    </div>
                );
            }
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/category/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => handleCheckCategory(row.original)}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ];

    return (
        <div className="categories">
            <Table
                nameBold="Categories"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={categories}
                icon={CategoryIcon}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {categoryToDelete && !categoryIsUsed && (
                <DeleteModal
                    onClose={() => setCategoryToDelete(null)}
                    onConfirm={handleDelete}
                    name="Categories > "
                    item={categoryToDelete.name}
                />
            )}
            {categoryIsUsed && categoryToDelete && (
                <ConfirmModal
                    onClose={() =>
                    {
                        setCategoryIsUsed(false)
                        setCategoryToDelete(false)
                    }}
                    item={categoryToDelete.name}
                    name="Categories >"
                    variant="confirm"
                >
                    You can't delete a category that is used in a recipe.
                </ConfirmModal>
            )}
        </div>
    );
};

export default Categories;
