import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import "./Layout.scss"

const Layout = ({variant}) => {
    return (
        <div className="layout">
            <Navbar variant={variant}/>
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;