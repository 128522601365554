import './InputForm.scss';
import classNames from 'classnames';
import { useState } from "react";
import {ReactComponent as showIcon} from "../../../assets/icons/show_icon.svg";
import {ReactComponent as unShowIcon} from "../../../assets/icons/unshow_icon.svg";
import Icon from "../../../assets/Icon";

const InputForm = ({
                       id,
                       label,
                       value,
                       type = "text",
                       placeholder,
                       errorMessage,
                       required = false,
                       onChange,
                       touched,
                       variant,
                       step,
                       onFocus,
                   }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="input-form">
            {label &&
                <label className="label">
                    {required && <span className="required">*</span>}
                    {label}
                </label>
            }
            <div className="container">
                <div className="group">
                    <input
                        id={id}
                        type={type === 'password' && isPasswordVisible ? 'text' : type}
                        className={classNames("input", variant, {
                            'error': touched && errorMessage
                        })}
                        value={value}
                        placeholder={touched && errorMessage ? errorMessage : placeholder}
                        onChange={onChange}
                        step={step}
                        onFocus={onFocus}
                    />
                    {type === 'password' && (
                        <button
                            type="button"
                            className="toggle-button"
                            onClick={togglePasswordVisibility}
                        >
                            {isPasswordVisible ? <Icon icon={showIcon}/> : <Icon icon={unShowIcon}/> }
                        </button>
                    )}
                    {variant === 'timer' && <span className='time'>min</span>}
                </div>
                {errorMessage && touched && value && <span className="error-message">{errorMessage}</span> }
            </div>
        </div>
    );
};

export default InputForm;