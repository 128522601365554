import { useEffect, useState } from "react";
import * as actions from './UsersActions.js';
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as UserIcon } from "../../assets/icons/users_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import ConfirmModal from "../../components/ux/ModalConfirm/ConfirmModal";
import {useSorting} from "../../hooks/useSorting";
import {usePagination} from "../../hooks/usePagination";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [userToDelete, setUserToDelete] = useState(null);
    const navigate = useNavigate();

    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();

    const currentUser = JSON.parse(localStorage.getItem("USER_PROFILE"))

    useEffect(() => {
        reload();
    }, [page, sorting]);

    const reload = () => {
        const filters = {
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        }
        actions.loadUsers(filters, data => {
            setUsers(data.users);
            updatePagination(data.pagination);
        });
    };

    const handleDelete = () => {
        if (userToDelete) {
            actions.deleteUser(userToDelete.id, () => {
                setUsers(prevUsers => prevUsers.filter(user => user.id !== userToDelete.id));
                setUserToDelete(null);
            });
        }
    };

    const handleAdd = () => {
        navigate('/user/');
    }

    const columns = [
        {
            Header: 'User',
            accessor: 'name',
            Cell: ({ row }) => {
                const imageUrl = row.original.image?.url;

                return (
                    <div className="cell">
                        {imageUrl ?
                            <img src={imageUrl} alt={row.original.name} />
                            :
                            <Icon icon={ProfileIcon} />
                        }
                        <span>{row.original.name} {row.original.surname}</span>
                    </div>
                );
            }
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/user/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => setUserToDelete(row.original)}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ];
    return (
        <div className="users">
            <Table
                nameBold="Users"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={users}
                icon={UserIcon}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {userToDelete && (
                <DeleteModal
                    onClose={() => setUserToDelete(null)}
                    onConfirm={handleDelete}
                    name="Users > "
                    item={userToDelete.name}
                />
            )}
            {currentUser.id === userToDelete?.id  && (
                <ConfirmModal
                    onClose={() => {
                        setUserToDelete(null);
                    }}
                    item={userToDelete.name}
                    name="Users >"
                >
                    You can't delete your own account
                </ConfirmModal>
            )}
        </div>
    );
};

export default Users;
