import ReactPaginate from "react-paginate"
import "./Pagination.scss"
import Icon from "../../../assets/Icon";
import {ReactComponent as leftArrowIcon} from "../../../assets/icons/left_arrow_icon.svg";
import {ReactComponent as rightArrowIcon} from "../../../assets/icons/right_arrow_icon.svg";

const Pagination = ({totalPages, gotoPage}) => {
    const handleChangePage = (selected) => {
        gotoPage(selected + 1)
    }

        return (
                <ReactPaginate
                    containerClassName="pagination"
                    pageClassName="page-item"
                    breakClassName="break-label"
                    activeClassName="active"
                    previousClassName="previous-button"
                    nextClassName="next-button"
                    onPageChange={(event) => handleChangePage(event.selected)}
                    pageCount={totalPages || 0}
                    breakLabel="..."
                    previousLabel={<Icon icon={leftArrowIcon} />}
                    nextLabel={<Icon icon={rightArrowIcon} />}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                />

        );
    };

    export default Pagination;