import * as actions from "./SubpagesActions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Blog from "../../components/ux/Blog/Blog";
import "./SubpagePublic.scss"

const SubpagePublic = () => {
    const [subpageData, setSubpageData] = useState({});
    const { user_id, subpage } = useParams();

    console.log(subpageData)

    useEffect(() => {
        const params = {
            user_id,
            subpage_id: subpage
        }
        actions.loadPublicSubpage(params, setSubpageData);
    }, [user_id, subpage]);

    return (
        <div className="subpage-public">
            <div dangerouslySetInnerHTML={{ __html: subpageData?.content }} />
        </div>
    );
};

export default SubpagePublic;
