import apiClient from '../../utils/apiClient';

export const loadIngredients = (filters, callback) => {
    apiClient.get('/ingredients', { params: filters })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadIngredient = (id, callback) => {
    apiClient.get(`/ingredients/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteIngredient = (id, callback) => {
    apiClient.delete(`/ingredients/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveIngredient = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/ingredients/${id}` : `/ingredients`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(response => callback(response))
        .catch(error => console.error(error));
};

export const saveImage = (postData, callback) => {
    apiClient.post('/images', postData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => callback(response.data.id))
        .catch(error => console.error(error));
};
