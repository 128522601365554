import './Table.scss';
import Button from '../Button/Button';
import Icon from "../../../assets/Icon";
import { ReactComponent as upArrowIcon } from "../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as downArrowIcon } from "../../../assets/icons/down_arrow_icon.svg";
import { ReactComponent as upAndDownArrowIcon } from "../../../assets/icons/up_and_down_arrow_icon.svg";
import { useTable, useSortBy } from "react-table";
import Pagination from "../Pagination/Pagination";
import {useEffect} from 'react';

const Table = ({ name, nameBold, buttonOnClick, buttonLabel, columns, data, icon, isFilter, pagination, onPaginationChange, onSortingChange }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ["category.name", "Ingredient", "prep_time", "Tag"],
            },
            manualSortBy: true,
        },
        useSortBy
    );

    useEffect(() => {
        if (onSortingChange) {
            onSortingChange(sortBy);
        }
    }, [sortBy, onSortingChange]);

    const getSortIcon = (column) => {
        if (column.canSort) {
            if (column.isSorted) {
                return column.isSortedDesc ? <Icon icon={downArrowIcon} /> : <Icon icon={upArrowIcon} />;
            }
            return <Icon icon={upAndDownArrowIcon} />;
        }
        return null;
    };

    return (
        <div className="table">
            <header>
                <span><Icon icon={icon} /> {name} <b>{nameBold}</b></span>
                {buttonLabel && <Button label={buttonLabel} onClick={buttonOnClick} />}
            </header>
            <div className="filter">
                {isFilter && (
                    columns.map((column, columnIndex) => (
                        column.Filter && (
                            <div key={columnIndex} className="filter-select">
                                {column?.Filter()}
                            </div>
                        )
                    ))
                )}
            </div>
            <div className="content">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                        <span>
                                            {column.render('Header')}
                                            {getSortIcon(column)}
                                        </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <footer>
                <Pagination
                    totalPages={pagination.total_pages}
                    gotoPage={onPaginationChange}
                />
            </footer>
        </div>
    );
};

export default Table;
