import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DateInput.scss';
import classNames from "classnames";
import { format } from 'date-fns';

const DateInput = ({
                       id,
                       label,
                       value,
                       placeholder,
                       errorMessage,
                       required = false,
                       onChange,
                       touched,
                       variant,
                       onFocus,
                   }) => {
    const [startDate, setStartDate] = useState(value ? new Date(value) : null);

    const handleDateChange = (date) => {
        setStartDate(date);
        const formattedDate = date ? format(date, 'yyyy/MM/dd') : '';
        onChange(formattedDate);
    };

    return (
        <div className="date-input">
            {label &&
                <label htmlFor={id} className="label">
                    {required && <span className="required">*</span>}
                    {label}
                </label>
            }
            <div className="container">
                <div className="group">
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        className={classNames("input", variant, {
                            'error': touched && errorMessage
                        })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={touched && errorMessage ? errorMessage : placeholder}
                        onFocus={onFocus}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateInput;
