import * as actions from "./RecipesActions";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PublicGrid from "../../components/ux/PublicGrid/PublicGrid";
import {ReactComponent as NewIcon} from "../../assets/icons/new_icon.svg";
import { getYear } from 'date-fns';

const RecipesPublic = () => {
    const [recipes, setRecipes] = useState([])
    const [year, setYear] = useState(getYear(new Date()))
    const { user_id } = useParams();
    const reload = () => {
        const params = {
            year,
            user_id
        }
        actions.loadPublicRecipes(params, setRecipes)
    }

    useEffect(() => {
        reload()
    }, [year]);


    return (
        <div className="recipes-public">
            <PublicGrid
                data={recipes}
                icon={NewIcon}
                name="Recipes"
                year={year}
                onYearChange={setYear}
                variant="recipes"
            />
        </div>
    )
}

export default RecipesPublic