import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as actions from "./ArticlesActions";
import InputForm from "../../components/form/InputForm/InputForm";
import UploadForm from "../../components/form/UploadFrom/UploadForm";
import Form from "../../components/ux/Form/Form";
import { ReactComponent as ArticleIcon } from "../../assets/icons/article_icon.svg";
import { pick } from "lodash";
import Editor from "../../components/form/Editor/Editor";

const articleSchema = yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().required("Name is required"),
    content: yup.string()
        .required("Content is required")
        .test("content-validity", "Content is required", value => {
            if (value === '<p><br></p>') {
                return false;
            }
            return true;
        }),
    image: yup.object().nullable()
        .test("fileSize", "The file is too large. Max size is 30MB.", value => {
            if (value && value.file) {
                const maxSize = 30 * 1024 * 1024;
                return value.file.size <= maxSize;
            }
            return true;
        })
        .test("fileType", "Unsupported file format. Please upload an image.", value => {
            if (value && value.file) {
                const supportedFormats = ["image/jpeg", "image/png"];
                return supportedFormats.includes(value.file.type);
            }
            return true;
        })
});
const Article = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const initialNameRef = useRef("");

    const { setFieldValue, setValues, values, handleSubmit, errors, touched, handleChange } = useFormik({
        initialValues: {
            name: "",
            content: "",
            image: null
        },
        validationSchema: articleSchema,
        onSubmit: (values) => {
            let imageId = values.image && values.image.id;
            const saveArticle = () => {
                const articleData = {
                    article: {
                        ...pick(values, ["name", "content"]),
                        image_id: imageId,
                    }
                };
                actions.saveArticle(id, articleData, () => navigate("/articles"));
            };

            if (values.image && values.image.file) {
                const imageData = new FormData();
                imageData.append("image[image]", values.image.file);

                actions.saveImage(imageData, (data) => {
                    imageId = data.id;
                    saveArticle();
                });
            } else {
                saveArticle();
            }
        },
    });

    const handleFileChange = (file) => {
        const image = {
            url: URL.createObjectURL(file),
            filename: file.name,
            file: file,
        };
        setFieldValue("image", image);
    };

    const handleDeleteImage = () => {
        setFieldValue("image", null);
    };

    useEffect(() => {
        if (id) {
            actions.loadArticle(id, (article) => {
                setValues(article);
                initialNameRef.current = article.name;
            });
        }
    }, [id, setValues]);

    return (
        <div className="article">
            <Form
                name="Articles > "
                nameBold={id ? initialNameRef.current : "New"}
                buttonLabel="Save"
                buttonType="submit"
                icon={ArticleIcon}
                onSubmit={handleSubmit}
                isFooterButton
            >
                <InputForm
                    id="name"
                    required
                    label="Name"
                    errorMessage={errors.name}
                    touched={touched.name}
                    value={values.name}
                    onChange={handleChange}
                />
                <Editor
                    id="content"
                    label="Content"
                    required
                    value={values.content}
                    onChange={(content) => setFieldValue("content", content)}
                    error={errors.content && touched.content ? errors.content : ''}
                />
                <UploadForm
                    onFileChange={handleFileChange}
                    errorMessage={errors.image}
                    image={values.image}
                    onDelete={handleDeleteImage}
                />
            </Form>
        </div>
    );
};

export default Article;
