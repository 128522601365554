import Icon from "../../../assets/Icon";
import {ReactComponent as alertIcon} from "../../../assets/icons/alert_icon.svg";
import "./Alert.scss"

const Alert = ({message}) => {
    return (
        <div className="alert">
            <Icon icon={alertIcon} />
            {message}
        </div>
    )
}

export default Alert