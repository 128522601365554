import {useNavigate, useLocation, useParams} from "react-router-dom";
import Select from "../form/Select/Select";
import './Navbar.scss';
import Button from "../ux/Button/Button";
import classNames from 'classnames';
import { ReactComponent as RecipeIcon } from "../../assets/icons/recipe_icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/users_icon.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart_icon.svg";
import { ReactComponent as ArticleIcon } from "../../assets/icons/article_icon.svg";
import { ReactComponent as SubpageIcon } from "../../assets/icons/subpage_icon.svg";
import * as actions from "../../pages/login/LoginActions";
import ElasticSearch from "../ux/ElasticSearch/ElasticSearch";
import {useEffect, useState} from "react";

const Navbar = ({ children, variant }) => {
    const [subpages, setSubpages] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { user_id } = useParams();

    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));

    const isActivePath = (path) => location.pathname.startsWith(path);

    const handleChange = (value) => {
        if (value.id === 'logout') {
            actions.logout(() => navigate('/'));
        } else {
            navigate(value.id);
        }
    };

    useEffect(() => {
        if (variant === 'guest') {
            actions.loadPublicSubpages({ user_id }, setSubpages);
        }
    }, []);

    const getIcon = (name) => {
        switch (name) {
            case 'Articles':
                return ArticleIcon;
            case 'Recipes':
                return RecipeIcon;
            default:
                return null;
        }
    };

    const getPath = (name, id) => {
        if (name === 'Articles' || name === 'Recipes') {
            return `/public/${user_id}/${name.toLowerCase()}`;
        }
        return `/public/${user_id}/${id}`;
    };

    return (
        <>
            <div className={classNames('navbar', variant)}>
                {variant === 'admin' && (
                    <>
                        <Button
                            onClick={() => navigate("/users")}
                            variant={classNames("menu", {
                                "menu-active": isActivePath("/user")
                            })}
                            type="button"
                            label="Users"
                            icon={UserIcon}
                        />
                        <Select
                            options={optionsMenu}
                            onChange={handleChange}
                            placeholder="Admin"
                            variant="profile"
                            profileImage={user?.image_url}
                        />
                    </>
                )}
                {variant === 'user' && (
                    <>
                        <div className="buttons">
                            <Button
                                onClick={() => navigate("/recipes")}
                                variant={classNames("menu", {
                                    "menu-active": isActivePath("/recipe")
                                })}
                                type="button"
                                label="Recipes"
                                icon={RecipeIcon}
                            />
                            <Button
                                onClick={() => navigate("/articles")}
                                variant={classNames("menu", {
                                    "menu-active": isActivePath("/article")
                                })}
                                type="button"
                                label="Articles"
                                icon={ArticleIcon}
                            />
                            <Button
                                onClick={() => navigate("/subpages")}
                                variant={classNames("menu", {
                                    "menu-active": isActivePath("/subpage")
                                })}
                                type="button"
                                label="Subpages"
                                icon={SubpageIcon}
                            />
                            <Button
                                onClick={() => navigate("/reports")}
                                variant={classNames("menu", {
                                    "menu-active": isActivePath("/reports")
                                })}
                                type="button"
                                label="Reports"
                                icon={ChartIcon}
                            />
                        </div>
                        <ElasticSearch />
                        <Select
                            options={options}
                            onChange={handleChange}
                            placeholder="Settings"
                            variant="menu"
                        />
                        <Select
                            options={optionsMenu}
                            onChange={handleChange}
                            placeholder={user?.name}
                            variant="profile"
                            profileImage={user?.image_url}
                        />
                    </>
                )}
                {variant === 'guest' && (
                    <div className="buttons">
                        {subpages?.map(subpage => (
                            <Button
                                key={subpage.id}
                                onClick={() => navigate(getPath(subpage.name, subpage.id))}
                                variant={classNames("menu", {
                                    "menu-active": isActivePath(getPath(subpage.name, subpage.id))
                                })}
                                type="button"
                                label={subpage.name}
                                icon={getIcon(subpage.name)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div>{children}</div>
        </>
    );
};

export default Navbar;

const options = [
    { id: 'categories', name: 'Categories' },
    { id: 'tags', name: 'Tags' },
    { id: 'ingredients', name: 'Ingredients' }
];

const optionsMenu = [
    { id: 'logout', name: 'Log out' }
];
