import apiClient from '../../utils/apiClient';

export const loadArticles = (params, callback) => {
    apiClient.get('/articles', { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};
export const loadArticle = (id, callback) => {
    apiClient.get(`/articles/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteArticle = (id, callback) => {
    apiClient.delete(`/articles/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveArticle = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/articles/${id}` : `/articles`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(response => callback(response))
        .catch(error => console.error(error));
};

export const saveImage = (postData, callback) => {
    apiClient.post('/images', postData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => callback(response.data.image))
        .catch(error => console.error(error));
};

export const loadPublicArticles = (params, callback) => {
    apiClient.get(`/articles/public_index`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadPublicArticle = (params, callback) => {
    apiClient.get(`/articles/public_show`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};