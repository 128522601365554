import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import './UploadForm.scss';
import { ReactComponent as IconUpload } from '../../../assets/icons/upload_icon.svg';
import Icon from '../../../assets/Icon';
import DeleteModal from "../../ux/ModalDelete/DeleteModal";

const UploadForm = ({ onFileChange, errorMessage, image, onDelete }) => {
    const [deleteModal, setDeleteModal] = useState(false);


    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                onFileChange(file);
            };
            reader.readAsDataURL(file);
        });
    }, [onFileChange]);

    const handleDelete = () => {
        setDeleteModal(false);
        onDelete()
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: image && image.url && !errorMessage
    });

    const renderDropZoneContent = () => {
        if (image && image.url) {
            return (
                <div className="drop-zone">
                    {errorMessage ? (
                        <span className="error">{errorMessage}</span>
                    ) : (
                        <>
                            <img src={image.url} alt="Uploaded" onClick={() => setDeleteModal(true)} />
                            <span>{image.filename}</span>
                        </>
                    )}
                </div>
            );
        }

        if (isDragActive) {
            return (
                <div className="drop-files">
                    <Icon icon={IconUpload} />
                </div>
            );
        }

        return (
            <div className="drag-files">
                <div className="circle">

                <Icon icon={IconUpload} />
                </div>
                <h4><span>Click to Upload</span> or drag and drop</h4>
                <span>(Max. File size: 30MB)</span>
            </div>
        );
    };

    return (
        <div className="upload-form">
            <span className="label">Photo</span>
            <div className="zone" {...getRootProps()}>
                <input {...getInputProps()} />
                {renderDropZoneContent()}
            </div>
            {deleteModal && (
                <DeleteModal
                    onClose={() => setDeleteModal(false)}
                    onConfirm={handleDelete}
                    name="Confirm Deletion >"
                    item={image.filename}
                />
            )}
        </div>
    );
};

export default UploadForm;
