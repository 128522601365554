import apiClient from "../../utils/apiClient";

export const searchItem = (params, callback) => {
    apiClient.get('/search/record_types', { params })
        .then(response => callback(response.data.results))
        .catch(error => console.error(error));
};

export const loadItems = (params, callback) => {
    apiClient.get('/search', { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteItem = (id, item, callback) => {
    let endpoint;

    switch (item) {
        case 'Tag':
            endpoint = 'tags';
            break;
        case 'Ingredient':
            endpoint = 'ingredients';
            break;
        case 'Recipe':
            endpoint = 'recipes';
            break;
        case 'Category':
            endpoint = 'categories';
            break;
        default:
            console.error(`Unsupported item type: ${item}`);
            return;
    }

    apiClient.delete(`/${endpoint}/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const checkCategoryUsage = (id, callback) => {
    apiClient.get(`/categories/${id}/used`)
        .then(response => {
            callback(response.data.used);
        })
};