import { useEffect, useState } from "react";
import * as actions from './IngredientsActions.js';
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/icons/default_avatar_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as IngredietnIcon } from "../../assets/icons/ingredient_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import {useSorting} from "../../hooks/useSorting";
import {usePagination} from "../../hooks/usePagination";

const Ingredietns = () => {
    const [ingredients, setIngredients] = useState([]);
    const [ingredientToDelete, setIngredientToDelete] = useState(null);
    const navigate = useNavigate();

    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();

    useEffect(() => {
        reload();
    }, [page, sorting]);

    const reload = () => {
        const filters = {
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        }
        actions.loadIngredients(filters, data => {
            setIngredients(data.ingredients);
            updatePagination(data.pagination);
        });
    };

    const handleDelete = () => {
        if (ingredientToDelete) {
            actions.deleteIngredient(ingredientToDelete.id, () => {
                setIngredients(prevIngredients => prevIngredients.filter(ingredient => ingredient.id !== ingredientToDelete.id));
                setIngredientToDelete(null);
            });
        }
    }

    const handleAdd = () => {
        navigate('/ingredient/');
    }

    const columns = [
        {
            Header: 'Ingredient',
            accessor: 'name',
            Cell: ({ row }) => {
                const imageUrl = row.original.image?.url;

                return (
                    <div className="cell">
                        {imageUrl ?
                            <img src={imageUrl} alt={row.original.name} />
                            :
                            <Icon icon={IconAvatar} />
                        }
                        <span>{row.original.name}</span>
                    </div>
                );
            }
        },
        {
            Header: 'Units',
            accessor: 'unit' 
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/ingredient/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => setIngredientToDelete(row.original)}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ];

    return (
        <div className="ingredients">
            <Table
                nameBold="Ingredients"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={ingredients}
                icon={IngredietnIcon}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {ingredientToDelete && (
                <DeleteModal
                    onClose={() => setIngredientToDelete(null)}
                    onConfirm={handleDelete}
                    name="Ingredient > "
                    item={ingredientToDelete.name}
                />
            )}
        </div>
    );
};

export default Ingredietns;
