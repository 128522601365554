import './TextAreaForm.scss'
import classNames from 'classnames';
import React from "react";

const TextAreaForm = ({id, label, value, placeholder, errorMessage, required = false, onChange, touched}) => {
    return (
        <div className="text-area-form">
            <label className="label">
                {required && <span className="required">*</span>}
                {label}
            </label>
            <div className="container">
                <textarea
                    id={id}
                    className={classNames("text-area", {
                        'error': touched && errorMessage
                    })}
                    value={value}
                    placeholder={touched && errorMessage ? errorMessage : placeholder}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default TextAreaForm;
