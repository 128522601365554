import apiClient from '../../utils/apiClient';
import { jwtDecode } from 'jwt-decode';
import { pick } from 'lodash';

export const login = (postData, callback) => {
    localStorage.removeItem("ACCESS_TOKEN");
    apiClient.post('/users/sign_in', { user: postData })
        .then(response => {
            const token = response.headers.authorization;
            const decodedUser = jwtDecode(token);

            localStorage.setItem("ACCESS_TOKEN", token);
            localStorage.setItem("USER_PROFILE", JSON.stringify(pick(decodedUser, ["id", "name", "role", "image_url"])))

            callback(decodedUser.role);
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                callback(false, 'The email or password are incorrect. Please try again');
            } else {
                callback(false, 'An unexpected error occurred');
            }
        });
};

export const logout = (callback) => {
    apiClient.post('/users/sign_out')
        .then(() => {
            localStorage.removeItem("ACCESS_TOKEN");
            localStorage.removeItem("USER_PROFILE");
            callback();
        })
        .catch(error => {
            if (error.response && error.response.status === 404) {
                localStorage.removeItem("ACCESS_TOKEN");
                localStorage.removeItem("USER_PROFILE");
                callback();
            } else {
                console.error(error);
                callback('An unexpected error occurred');
            }
        });
};

export const loadPublicSubpages = (params, callback) => {
    apiClient.get(`/subpages/public_index`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};