import './Modal.scss';
import Icon from "../../../assets/Icon";

const Modal = ({ onClose, name, item, children, buttons, icon }) => {
    return (
        <div className="modal">
            <div className="background" onClick={onClose} />
            <div className="centered">
                <div className="content">
                    <div className="header">
                        <h5> {icon && <Icon icon={icon}/>} {name} <b>{item}</b></h5>
                    </div>
                    <div className="body">
                        {children}
                    </div>
                    <div className="actions">
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

