import { format, parseISO } from 'date-fns';
import { ReactComponent as UndefinedIcon } from "../../../assets/icons/undefined_image_icon.svg";
import { ReactComponent as PrepTimeIcon } from "../../../assets/icons/prep_time_icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_icon.svg";
import { ReactComponent as NewIcon } from "../../../assets/icons/new_icon.svg";
import Icon from "../../../assets/Icon";
import "./Blog.scss";

const Blog = ({ data, children }) => {
    const imageUrl = data?.category?.url || data?.user?.image?.url;
    const formattedDate = data?.created_at ? format(parseISO(data.created_at), 'MMMM dd, yyyy') : null;

    return (
        <div className="blog">
            <header>
                <span><Icon icon={NewIcon}/> <b>You're reading</b></span>
            </header>
            <div className="body">
                <h1>{data.name}</h1>
                <div className="main">
                    <div className="profile">
                        {imageUrl ? <img src={imageUrl} alt="Category or User"/> : <Icon icon={UndefinedIcon}/>}
                        {data.category && <span className="category">{data.category.name}</span>}
                        {data.user && <span className="user">By {data.user.name}</span>}
                        <span className="date">{formattedDate}</span>
                    </div>
                    <div className="main-image">
                        {data.image?.url ? <img src={data.image.url} alt="Main"/> : <Icon icon={UndefinedIcon}/>}
                    </div>
                    <div className="tags">
                        {data.tags?.map(tag => (
                            <span key={tag.id} className="tag">
                                        {tag.name} <Icon icon={CloseIcon}/>
                                    </span>
                        ))}
                    </div>
                    {data.prep_time && (
                        <span className="prep-time">
                                    <Icon icon={PrepTimeIcon}/>{data.prep_time} min
                                </span>
                    )}
                    {data.ingredients?.length > 0 && (
                        <>
                            <h2>Ingredients</h2>
                            <div className="ingredients">
                                {data.ingredients.map(ingredient => (
                                    <div key={ingredient.id} className="ingredient-item">
                                        {ingredient.image?.url ?
                                            <img src={ingredient.image.url} alt={ingredient.name}/> :
                                            <Icon icon={UndefinedIcon}/>
                                        }
                                        <span>{ingredient.name} - {ingredient.amount} {ingredient.unit}</span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Blog;
