import apiClient from '../../utils/apiClient';

export const loadTags = (filters, callback) => {
    apiClient.get('/tags', { params: filters })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadTag = (id, callback) => {
    apiClient.get(`/tags/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteTag = (id, callback) => {
    apiClient.delete(`/tags/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveTag = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/tags/${id}` : `/tags`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(response => callback(response))
        .catch(error => console.error(error));
};
