import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from "recharts";
import './RecipesBarChart.scss';
import { format, eachMonthOfInterval, parse } from 'date-fns';
import { groupBy, map, sumBy, reduce, orderBy } from "lodash";

const RecipesBarChart = ({ totalValue, data, filters }) => {
    const groupedData = groupBy(orderBy(data, 'date'), 'date');

    const allMonths = eachMonthOfInterval({
        start: parse(filters.from, 'yyyy/MM/dd', new Date()),
        end: parse(filters.to, 'yyyy/MM/dd', new Date())
    });

    const formattedData = map(allMonths, (month) => {
        const dateString = format(month, 'yyyy-MM');

        const categoryCounts = reduce(data, (counts, category) => {
            counts[category.name] = sumBy(groupedData[dateString], item => item.name === category.name && item.value);
            return counts;
        }, {});

        return {
            name: format(month, 'MMM yyyy'),
            ...categoryCounts
        };
    });

    const allKeys = data.map(category => category.name);

    const renderLegend = () => {
        const orderedPayload = data.map(category => ({
            value: category.name,
            color: category.color
        }));

        return (
            <ul className="legend">
                {orderedPayload?.map((entry, index) => (
                    <li key={`item-${index}`}>
                        <span
                            className="circle"
                            style={{ backgroundColor: entry.color }}
                        />
                        {entry.value}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="recipes-bar-chart">
            <div className="header">
                <span>From: {filters.from} To: {filters.to}</span>
                <h1>{totalValue}</h1>
                <span>Recipes</span>
            </div>
            <BarChart
                width={1200}
                height={300}
                data={formattedData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barCategoryGap="5%"
                barGap={1}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis strokeDasharray="5 5" dataKey="name" stroke="#c0bdbd" tick={{ fill: '#6f7376', fontSize: 14 }} />
                <YAxis strokeDasharray="5 5" stroke="#c0bdbd" tick={{ fill: '#6f7376', fontSize: 14 }} />
                <Legend
                    content={renderLegend}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
                {allKeys.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={data.find(d => d.name === key)?.color}
                    />
                ))}
            </BarChart>
        </div>
    );
};

export default RecipesBarChart;
