import * as actions from "./RecipesActions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Blog from "../../components/ux/Blog/Blog";

const RecipePublic = () => {
    const [recipe, setRecipe] = useState({});
    const { user_id, id } = useParams();

    useEffect(() => {
        const params = {
            user_id,
            recipe_id: id
        }
        actions.loadPublicRecipe(params, setRecipe);
    }, []);

    return (
        <div className="recipe-public">
            <Blog data={recipe}>
                <div className="description">
                    {recipe.description}
                </div>
            </Blog>
        </div>
    );
};

export default RecipePublic;
