import {useEffect, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from "../../components/form/InputForm/InputForm";
import { useFormik } from "formik";
import UploadForm from "../../components/form/UploadFrom/UploadForm";
import * as actions from "./CategoriesActions";
import * as yup from "yup";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category_icon.svg";
import Form from "../../components/ux/Form/Form";

const categorySchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    image: yup.object().nullable()
        .test("fileSize", "The file is too large. Max size is 30MB.", value => {
            if (value && value.file) {
                const maxSize = 30 * 1024 * 1024;
                return value.file.size <= maxSize;
            }
            return true;
        })
        .test("fileType", "Unsupported file format. Please upload an image.", value => {
            if (value && value.file) {
                const supportedFormats = ["image/jpeg", "image/png"];
                return supportedFormats.includes(value.file.type);
            }
            return true;
        })
});

const Category = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialNameRef = useRef("");

    const { values, errors, handleChange, handleSubmit, setValues, setFieldValue, touched } = useFormik({
        initialValues: {
            name: '',
            image: null
        },
        validationSchema: categorySchema,
        onSubmit: (values) => {
            let imageId = values.image && values.image.id;

            const saveCategory = () => {
                const categoryData = {
                    name: values.name,
                    image_id: imageId
                };

                actions.saveCategory(id, categoryData, () => navigate("/categories"));
            };

            if (values.image && values.image.file) {
                const imageData = new FormData();
                imageData.append("image[image]", values.image.file);

                actions.saveImage(imageData, (returnedId) => {
                    imageId = returnedId;
                    saveCategory()
                })
            } else {
                saveCategory();
            }
        },
    });


    const handleFileChange = (file) => {
        const image = {
            url: URL.createObjectURL(file),
            filename: file.name,
            file: file,
        };
        setFieldValue("image", image);
    };

    const handleDeleteImage = () => {
        setFieldValue("image", {
            id: null,
        });
    };

    useEffect(() => {
        if (id) {
            actions.loadCategory(id, category => {
                setValues(category);
                initialNameRef.current = category.name;
            });
        }
    }, [id, setValues]);

    return (
        <Form
            name="Categories > "
            nameBold={id ? initialNameRef.current : "New"}
            buttonLabel="Save"
            buttonType="submit"
            icon={CategoryIcon}
            onSubmit={handleSubmit}
        >
            <InputForm
                id="name"
                required
                label="Name"
                errorMessage={errors.name}
                touched={touched.name}
                value={values.name}
                onChange={handleChange}
            />
            <UploadForm
                onFileChange={handleFileChange}
                errorMessage={errors.image}
                image={values.image}
                onDelete={handleDeleteImage}
            />
        </Form>
    );
}

export default Category;
