import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ id, label, checked, onChange }) => {
    return (
        <div className="checkbox">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={id}>
                {label}
                <span className="checkmark"></span>
            </label>
        </div>
    );
};

export default Checkbox;
