import { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as actions from "./RecipesActions";
import InputForm from "../../components/form/InputForm/InputForm";
import UploadForm from "../../components/form/UploadFrom/UploadForm";
import Select from "../../components/form/Select/Select";
import Form from "../../components/ux/Form/Form";
import { ReactComponent as RecipeIcon } from "../../assets/icons/recipe_icon.svg";
import TableForm from "../../components/form/TableForm/TableForm";
import TextAreaForm from "../../components/form/TextAreaForm/TextAreaForm";
import ModalIngredient from "../../components/ux/./ModalIngredient/ModalIngredient";
import { pick } from "lodash";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import { ReactComponent as IconAvatar } from "../../assets/icons/default_avatar_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash_icon.svg";
import Icon from "../../assets/Icon";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";

const recipeSchema = yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    prep_time: yup.number().required(" "),
    category: yup.object().required("Category is required"),
    tags: yup.array().nullable(),
    ingredients: yup.array().nullable(),
    image: yup.object().nullable()
        .test("fileSize", "The file is too large. Max size is 30MB.", value => {
            if (value && value.file) {
                const maxSize = 30 * 1024 * 1024;
                return value.file.size <= maxSize;
            }
            return true;
        })
        .test("fileType", "Unsupported file format. Please upload an image.", value => {
            if (value && value.file) {
                const supportedFormats = ["image/jpeg", "image/png"];
                return supportedFormats.includes(value.file.type);
            }
            return true;
        })
});

const Recipe = () => {
    const { id } = useParams();
    const [options, setOptions] = useState({
        categoryOption: [],
        tagOption: [],
        ingredientOption: []
    });
    const [modalState, setModalState] = useState({});
    const navigate = useNavigate();
    const initialNameRef = useRef("");

    const {setFieldValue, setValues, values, handleSubmit, errors, touched, handleChange} = useFormik({
        initialValues: {
            name: "",
            description: "",
            prep_time: "",
            category: null,
            tags: [],
            ingredients: [],
            image: null
        },
        validationSchema: recipeSchema,
        onSubmit: (values) => {
            let imageId = values.image && values.image.id;

            const saveRecipe = () => {
                const recipeData = {
                    recipe: {
                        ...pick(values, ["name", "description", "prep_time"]),
                        category_id: values.category?.id,
                        tag_ids: values.tags.map(tag => tag.id),
                        image_id: imageId,
                        recipe_ingredients_attributes: values.ingredients
                    }
                };
                actions.saveRecipe(id, recipeData, () => navigate("/recipes"));
            };

            if (values.image && values.image.file) {
                const imageData = new FormData();
                imageData.append("image[image]", values.image.file);

                actions.saveImage(imageData, (returnedId) => {
                    imageId = returnedId;
                    saveRecipe();
                });
            } else {
                saveRecipe();
            }
        },
    });

    const handleFileChange = (file) => {
        const image = {
            url: URL.createObjectURL(file),
            filename: file.name,
            file: file,
        };
        setFieldValue("image", image);
    };

    const handleDeleteImage = () => {
        setFieldValue("image", null);
    };

    const handleConfirmIngredient = (ingredientData) => {
        const selectedIngredient = {
            ...options.ingredientOption.find(ing => ing.id === ingredientData.ingredient_id),
            amount: ingredientData.amount,
            ingredient_id: ingredientData.ingredient_id,
        };

        if (modalState.type === 'edit') {
            handleUpdateIngredient(selectedIngredient);
        }

        if (modalState.type === 'add') {
            handleSaveIngredient(selectedIngredient);
        }
        setModalState({});
    };


    const handleUpdateIngredient = (selectedIngredient) => {
        setFieldValue(
            "ingredients",
            values.ingredients.map((ingredient) =>
                ingredient.ingredient_id === modalState.item.ingredient_id
                    ? {
                        ...pick(ingredient, ["id"]),
                        ...pick(selectedIngredient, ["name", "unit", "image", "amount", "ingredient_id"])
                    }
                    : ingredient
            )
        );
    };

    const handleSaveIngredient = (selectedIngredient) => {
        setFieldValue("ingredients", [
            ...values.ingredients,
            {
                ...pick(selectedIngredient, ["name", "unit", "image", "amount", "ingredient_id"])
            }
        ]);
    };

    const handleDeleteIngredient = () => {
        setFieldValue(
            "ingredients",
            values.ingredients.map((ingredient) =>
                ingredient.ingredient_id === modalState.item.ingredient_id ? { ...ingredient, _destroy: true } : ingredient
            )
        );
        setModalState({});
    };

    const handleAddIngredient = () => {
        setModalState({
            type: "add",
            item: { ingredient_id: null, amount: '' }
        });
    };

    useEffect(() => {
        if (id) {
            actions.loadRecipe(id, (recipe) => {
                setValues(recipe);
                initialNameRef.current = recipe.name;
            });
        }

        actions.loadCategories((categories) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                categoryOption: categories.map(category => category),
            }));
        });

        actions.loadTags((tags) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                tagOption: tags.map(tag => tag),
            }));
        });

        actions.loadIngredients((ingredients) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                ingredientOption: ingredients.map(ingredient => ingredient),
            }));
        });
    }, [id, setValues]);

    const columns = useMemo(() => [
        {
            Header: "Photo",
            accessor: "image",
            Cell: ({ value, row }) => (
                <div className="cell">
                    {value && value.url ? <img src={value.url} alt={row.original.name} /> : <Icon icon={IconAvatar} />}
                </div>
            ),
        },
        { Header: "Ingredient", accessor: "name" },
        { Header: "Amount", accessor: "amount" },
        { Header: "Unit", accessor: "unit" },
        {
            Header: "Options",
            Cell: ({ row }) => (
                <>
                    <button onClick={() =>  setModalState({type: 'edit', item: row.original})} type="button">
                        <Icon icon={EditIcon} />
                    </button>
                    <button onClick={() => setModalState({type: 'delete', item: row.original})} type="button">
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            ),
        },
    ], [handleDeleteIngredient]);

    const availableIngredient = options.ingredientOption.filter(({ id }) =>
        !values.ingredients.some(item => !item._destroy && item.ingredient_id === id)
    );
    return (
        <>
            <Form
                name="Recipes > "
                nameBold={id ? initialNameRef.current : "New"}
                buttonLabel="Save"
                buttonType="submit"
                icon={RecipeIcon}
                onSubmit={handleSubmit}
            >
                <InputForm
                    id="name"
                    required
                    label="Name"
                    errorMessage={errors.name}
                    touched={touched.name}
                    value={values.name}
                    onChange={handleChange}
                />
                <TextAreaForm
                    id="description"
                    required
                    label="Description"
                    errorMessage={errors.description}
                    touched={touched.description}
                    value={values.description}
                    onChange={handleChange}
                />
                <InputForm
                    id="prep_time"
                    required
                    label="Preparation time"
                    errorMessage={errors.prep_time}
                    touched={touched.prep_time}
                    value={values.prep_time}
                    onChange={handleChange}
                    type="number"
                    variant="timer"
                />
                <Select
                    options={options.categoryOption}
                    id="category"
                    onChange={(option) => setFieldValue("category", option)}
                    value={values.category}
                    label="Category"
                    variant="primary"
                    errorMessage={errors.category}
                    touched={touched.category}
                    required
                />
                <Select
                    options={options.tagOption}
                    id="tags"
                    onChange={(option) => setFieldValue("tags", option)}
                    value={values.tags}
                    label="Tags"
                    variant="primary"
                    errorMessage={errors.tags}
                    touched={touched.tags}
                    isMulti
                />
                <TableForm
                    data={values.ingredients.filter((ingredient) => !ingredient._destroy)}
                    columns={columns}
                    onAdd={handleAddIngredient}
                    label="Ingredients"
                />
                <UploadForm
                    onFileChange={handleFileChange}
                    errorMessage={errors.image}
                    image={values.image}
                    onDelete={handleDeleteImage}
                />
            </Form>
            {modalState.type && (
                modalState.type === 'delete' ? (
                    <DeleteModal
                        name="Delete Ingredient >"
                        item={modalState.item.name}
                        onClose={() => setModalState({})}
                        onConfirm={handleDeleteIngredient}
                    />
                ) : (
                    <ModalIngredient
                        name="Ingredient > "
                        item={modalState.item.name ? modalState.item.name : "New"}
                        onClose={() => {
                            setModalState({});
                        }}
                        onConfirm={(ingredient) => handleConfirmIngredient(ingredient)}
                        options={availableIngredient}
                        initialIngredient={modalState.item}
                    />
                )
            )}
        </>
    );
};

export default Recipe;
