import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import * as actions from '../../../pages/search/SearchActions'
import InputForm from "../../form/InputForm/InputForm";
import Icon from "../../../assets/Icon";
import { ReactComponent as loupeIcon } from "../../../assets/icons/loupe_icon.svg";
import { ReactComponent as closeIcon } from "../../../assets/icons/close_icon.svg";
import debounce from "lodash/debounce";
import "./ElasticSearch.scss";
import classNames from "classnames";

const ElasticSearch = () => {
    const [inputValue, setInputValue] = useState('');
    const [searchData, setSearchData] = useState({ record_type: [], suggestion: '' });
    const searchContainerRef = useRef(null);

    const reload = useCallback(debounce((query) => {
        const params = {
            query
        };
        actions.searchItem(params, setSearchData);
    }, 300), []);

    useEffect(() => {
        setSearchData({ record_type: [], suggestion: '' });
        reload(inputValue);
    }, [inputValue, reload]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setSearchData({ record_type: [], suggestion: '' });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const clearInput = () => {
        setInputValue('');
        setSearchData({ record_type: [], suggestion: '' });
    };

    const { record_type, suggestion } = searchData;

    return (
        <div className="elastic-search" ref={searchContainerRef}>
            <div className="search-bar">
                <InputForm
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onFocus={() => reload(inputValue)}
                />
                <Icon icon={loupeIcon} />
                {inputValue && (
                    <button className="clear" onClick={clearInput}>
                        <Icon icon={closeIcon} />
                    </button>
                )}
            </div>
            <div className={classNames("search-results", {"active": record_type.length > 0 || suggestion})}>
                {(record_type.length > 0 || suggestion) && (
                    <ul>
                        {suggestion && (
                            <li>
                                <button onClick={() => setInputValue(suggestion)} className="suggestion">
                                    <Icon icon={loupeIcon} />
                                    {suggestion}
                                </button>
                            </li>
                        )}
                        {record_type.map((type, index) => (
                            <li key={index}>
                                <Link
                                    to={`/search/${type}/${inputValue}`}
                                    onClick={() => setSearchData({ record_type: [], suggestion: '' })}
                                >
                                    {inputValue}
                                    <span>in:{type}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ElasticSearch;
