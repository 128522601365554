import {useState} from "react";
export function useSorting() {
    const [sorting, setSorting] = useState([]);

    const onSortingChange = (newSorting) => {
        setSorting(newSorting);
    };

    return {
        sorting,
        onSortingChange,
        order: sorting.length ? (sorting[0]?.desc ? "DESC" : "ASC") : null,
        field: sorting.length ? sorting[0]?.id : null,
    };
}