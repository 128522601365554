import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from "../../components/form/InputForm/InputForm";
import { useFormik } from "formik";
import UploadForm from "../../components/form/UploadFrom/UploadForm";
import * as actions from "./IngredientsActions";
import * as yup from "yup";
import { ReactComponent as IngredientIcon } from "../../assets/icons/ingredient_icon.svg";
import Form from "../../components/ux/Form/Form";
import Select from "../../components/form/Select/Select";

const ingredientSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    unit: yup.object().required("Unit is required"),
    image: yup.object().nullable()
        .test("fileSize", "The file is too large. Max size is 30MB.", value => {
            if (value && value.file) {
                const maxSize = 30 * 1024 * 1024;
                return value.file.size <= maxSize;
            }
            return true;
        })
        .test("fileType", "Unsupported file format. Please upload an image.", value => {
            if (value && value.file) {
                const supportedFormats = ["image/jpeg", "image/png"];
                return supportedFormats.includes(value.file.type);
            }
            return true;
        })
});

const Ingredient = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialNameRef = useRef("");

    const { values, errors, handleChange, handleSubmit, setValues, setFieldValue, touched } = useFormik({
        initialValues: {
            name: '',
            image: null,
            unit: ''
        },
        validationSchema: ingredientSchema,
        onSubmit: (values) => {
            let imageId = values.image && values.image.id;

            const saveIngredient = () => {
                const categoryData = {
                    name: values.name,
                    unit: values.unit.id,
                    image_id: imageId
                };

                actions.saveIngredient(id, categoryData, () => navigate("/ingredients"));
            };

            if (values.image && values.image.file) {
                const imageData = new FormData();
                imageData.append("image[image]", values.image.file);

                actions.saveImage(imageData, (returnedId) => {
                    imageId = returnedId;
                    saveIngredient()
                })
            } else {
                saveIngredient();
            }
        }
    });


    const handleFileChange = (file) => {
        const image = {
            url: URL.createObjectURL(file),
            filename: file.name,
            file: file,
        };
        setFieldValue("image", image);
    };

    const handleDeleteImage = () => {
        setFieldValue("image",null);
    };

    const formatUnit = (unit) => ({
        id: unit,
        name: unit
    });

    useEffect(() => {
        if (id) {
            actions.loadIngredient(id, ingredient => {
                setValues({
                    ...ingredient,
                    unit: formatUnit(ingredient.unit)
                });
                initialNameRef.current = ingredient.name
            });
        }
    }, [id, setValues, setFieldValue]);

    return (
        <Form
            name="Ingredient > "
            nameBold={id ? initialNameRef.current : "New"}
            buttonLabel="Save"
            buttonType="submit"
            icon={IngredientIcon}
            onSubmit={handleSubmit}
        >
            <InputForm
                id="name"
                required={true}
                label="Name"
                errorMessage={errors.name}
                touched={touched.name}
                value={values.name}
                onChange={handleChange}
            />
            <Select options={options}
                    id="unit"
                    onChange={(unit)=> setFieldValue("unit", unit)}
                    value={values.unit}
                    label="Unit"
                    variant="primary"
                    errorMessage={errors.unit}
                    touched={touched.unit}
                    required
            />
            <UploadForm
                onFileChange={handleFileChange}
                errorMessage={errors.image}
                image={values.image}
                onDelete={handleDeleteImage}
            />
        </Form>
    );
}

export default Ingredient;

const options = [
    { id: 'Grams', name: 'Grams' },
    { id: 'Quantity', name: 'Quantity' },
    { id: 'Litters', name: 'Litters' },
    { id: 'Slices', name: 'Slices' },
    { id: 'Tablespoons', name: 'Tablespoons' }
];