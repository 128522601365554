import * as actions from "./ArticlesActions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Blog from "../../components/ux/Blog/Blog";

const ArticlePublic = () => {
    const [article, setArticle] = useState({});
    const { user_id, id } = useParams();

    useEffect(() => {
        const params = {
            user_id,
            article_id: id
        }
        actions.loadPublicArticle(params, setArticle);
    }, []);

    return (
        <div className="article-public">
            <Blog data={article}>
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </Blog>
        </div>
    );
};

export default ArticlePublic;
