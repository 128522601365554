import apiClient from '../../utils/apiClient';

export const loadCategories = (filters, callback) => {
    apiClient.get('/categories', { params: filters })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadCategory = (id, callback) => {
    apiClient.get(`/categories/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteCategory = (id, callback) => {
    apiClient.delete(`/categories/${id}`)
        .then(() => callback())
};

export const saveCategory = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/categories/${id}` : `/categories`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveImage = (postData, callback) => {
    apiClient.post('/images', postData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => callback(response.data.id))
        .catch(error => console.error(error));
};

export const checkCategoryUsage = (id, callback) => {
    apiClient.get(`/categories/${id}/used`)
        .then(response => {
            callback(response.data.used);
        })
};