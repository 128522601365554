import {useEffect, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from "../../components/form/InputForm/InputForm";
import { useFormik } from "formik";
import * as actions from "./TagsActions";
import * as yup from "yup";
import { ReactComponent as TagIcon } from "../../assets/icons/tag_icon.svg";
import Form from "../../components/ux/Form/Form";

const tagSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
});

const Tag = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialNameRef = useRef("");

    const { values, errors, handleChange, handleSubmit, setValues, touched } = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: tagSchema,
        onSubmit: (values) => {
            actions.saveTag(id,{name: values.name}, () => navigate("/tags"))
        },
    });

    useEffect(() => {
        if (id) {
            actions.loadTag(id, tag => {
                setValues(tag);
                initialNameRef.current = tag.name
            });
        }
    }, [id, setValues]);

    return (
        <Form 
            name="Tags > " 
            nameBold={id ? initialNameRef.current : "New"}
            buttonLabel="Save" 
            buttonType="submit" 
            icon={TagIcon}
            onSubmit={handleSubmit}
        >
            <InputForm 
                id="name"
                required
                label="Name"
                errorMessage={errors.name} 
                touched={touched.name}
                value={values.name} 
                onChange={handleChange} 
            />
        </Form>
    );
}

export default Tag;
