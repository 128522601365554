import { useEffect, useState } from "react";
import * as actions from './TagsActions.js';
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as TagIcon } from "../../assets/icons/tag_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import {useSorting} from "../../hooks/useSorting";
import {usePagination} from "../../hooks/usePagination";

const Tags = () => {
    const [tags, setTags] = useState([]);
    const [tagToDelete, setTagToDelete] = useState(null);
    const navigate = useNavigate();

    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();

    useEffect(() => {
        reload();
    }, [page, sorting]);

    const reload = () => {
        const filters = {
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        }
        actions.loadTags(filters, data => {
            setTags(data.tags);
            updatePagination(data.pagination);
        });
    }

    const handleDelete = () => {
        if (tagToDelete) {
            actions.deleteTag(tagToDelete.id, () => {
                setTags(prevTags => prevTags.filter(tag => tag.id !== tagToDelete.id));
                setTagToDelete(null);
            });
        }
    }

    const handleAdd = () => {
        navigate('/tag/');
    }

    const columns = [
        {
            Header: 'Tag',
            accessor: 'name'
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/tag/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => setTagToDelete(row.original)}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ];

    return (
        <div className="tags">
            <Table
                nameBold="Tags"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={tags}
                icon={TagIcon}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {tagToDelete && (
                <DeleteModal
                    onClose={() => setTagToDelete(null)}
                    onConfirm={handleDelete}
                    name="Tags > "
                    item={tagToDelete.name}
                />
            )}
        </div>
    );
};

export default Tags;
