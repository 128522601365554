import apiClient from '../../utils/apiClient';

export const loadUsers = (filters, callback) => {
    apiClient.get('/users', { params: filters })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadUser = (id, callback) => {
    apiClient.get(`/users/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteUser = (id, callback) => {
    apiClient.delete(`/users/${id}`)
        .then(() => callback())
        .catch(error => {
            if (error.response && error.response.status === 403) {
                callback("You can't delete your profile!");
            } else {
                console.error(error)
            }
        });
};

export const saveUser = (id, postData, callback) => {
    const url = id ? `/users/${id}` : `/users`;
    const method = id ? 'put' : 'post';

    apiClient[method](url, postData)
        .then(response => callback(true))
        .catch(error => {
            if (error.response && error.response.status === 422) {
                callback(null, "Email is taken");
            } else {
                console.error(error)
            }
        });
};

export const saveImage = (postData, callback) => {
    apiClient.post('/images', postData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => callback(response.data.id))
        .catch(error => console.error(error));
};
