import { useEffect, useState, useMemo } from "react";
import * as actions from './SearchActions.js';
import { Link,  useParams } from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/icons/default_avatar_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as loupeIcon } from "../../assets/icons/loupe_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import ConfirmModal from "../../components/ux/ModalConfirm/ConfirmModal";
import {usePagination} from "../../hooks/usePagination";
import {useSorting} from "../../hooks/useSorting";

const Search = () => {
    const { model, query } = useParams();
    const [results, setResults] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [categoryIsUsed, setCategoryIsUsed] = useState(false);

    const { sorting, onSortingChange, order } = useSorting();
    const { page, perPage, pagination, onPaginationChange, updatePagination } = usePagination();

    useEffect(() => {
        reload();
    }, [model, query, page, sorting]);

    const reload = () => {
        const params = {
            query,
            model,
            page,
            per_page: perPage,
            sort: order
        };
        actions.loadItems(params, (data) => {
            setResults(data.results);
            updatePagination(data.pagination)
        });
    };

    const handleCheckItem = (item) => {
        if (item) {
            if (model === 'Category') {
                actions.checkCategoryUsage(item.record_id, (categoryUsed) => {
                    setCategoryIsUsed(categoryUsed);
                    setItemToDelete(item);
                });
            } else {
                setItemToDelete(item);
            }
        }
    };

    const handleDelete = () => {
        if (itemToDelete && !categoryIsUsed) {
            actions.deleteItem(itemToDelete.record_id, model, () => {
                setResults(prevResults => prevResults.filter(item => item.record_id !== itemToDelete.record_id));
                setItemToDelete(null);
            });
        }
    };


    const columns = useMemo(() => [
        {
            Header: model,
            accessor: 'hint',
            Cell: ({ row, value }) => {
                if (model !== 'Tag') {
                    const imageUrl = row.original.image?.url;

                    return (
                        <div className="cell">
                            {imageUrl ? (
                                <img src={imageUrl} alt={row.original.name} />
                            ) : (
                                <Icon icon={IconAvatar} />
                            )}
                            <span>{row.original.hint}</span>
                        </div>
                    );
                }
                return value;
            }
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/${model}/${row.original.record_id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => handleCheckItem(row.original)}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ], [results, itemToDelete, model]);


    return (
        <div className="search">
            <Table
                name="Search:"
                nameBold={query}
                columns={columns}
                data={results}
                icon={loupeIcon}
                onPaginationChange={onPaginationChange}
                pagination={pagination}
                onSortingChange={onSortingChange}
            />
            {itemToDelete && !categoryIsUsed && (
                <DeleteModal
                    onClose={() => setItemToDelete(null)}
                    onConfirm={handleDelete}
                    name={`${model} >`}
                    item={itemToDelete.hint}
                />
            )}
            {categoryIsUsed && itemToDelete && (
                <ConfirmModal
                    onClose={() =>
                    {
                        setCategoryIsUsed(false)
                        setItemToDelete(false)
                    }}
                    item={itemToDelete.name}
                    name={`${model} >`}
                    variant="confirm"
                >
                    You can't delete a category that is used in a recipe.
                </ConfirmModal>
            )}
        </div>
    );
};

export default Search;
