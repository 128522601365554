import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import * as actions from "./SubpagesActions";
import InputForm from "../../components/form/InputForm/InputForm";
import Form from "../../components/ux/Form/Form";
import { ReactComponent as SubpageIcon } from "../../assets/icons/subpage_icon.svg";
import { pick } from "lodash";
import Editor from "../../components/form/Editor/Editor";

const subpageSchema = yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().required("Name is required"),
    content: yup.string()
        .required("Content is required")
        .test("content-validity", "Content is required", value => {
            if (value === '<p><br></p>') {
                return false;
            }
            return true;
        }),
});
const Subpage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const initialNameRef = useRef("");

    const { setFieldValue, setValues, values, handleSubmit, errors, touched, handleChange } = useFormik({
        initialValues: {
            name: "",
            content: ""
        },
        validationSchema: subpageSchema,
        onSubmit: (values) => {
            const subpageData = {
                subpage: {
                    ...pick(values, ["name", "content"]),
                }
            };
            actions.saveSubpage(id, subpageData, () => navigate("/subpages"));
        },
    });

    useEffect(() => {
        if (id) {
            actions.loadSubpage(id, (subpage) => {
                setValues(subpage);
                initialNameRef.current = subpage.name;
            });
        }
    }, [id, setValues]);

    return (
        <div className="subpage">
            <Form
                name="Subpages > "
                nameBold={id ? initialNameRef.current : "New"}
                buttonLabel="Save"
                buttonType="submit"
                icon={SubpageIcon}
                onSubmit={handleSubmit}
                isFooterButton
            >
                <InputForm
                    id="name"
                    required
                    label="Name"
                    errorMessage={errors.name}
                    touched={touched.name}
                    value={values.name}
                    onChange={handleChange}
                />
                <Editor
                    id="content"
                    label="Content"
                    required
                    value={values.content}
                    onChange={(content) => setFieldValue("content", content)}
                    error={errors.content && touched.content ? errors.content : ''}
                />
            </Form>
        </div>
    );
};

export default Subpage;
