import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Button from "../Button/Button";
import Icon from "../../../assets/Icon";
import "./DropArea.scss";
import Pagination from "../Pagination/Pagination";

const DropArea = ({ children, buttonLabel, nameBold, icon, buttonOnClick, droppableId, pagination, onPaginationChange }) => {
    return (
        <div className="drop-area">
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <header>
                            <span><Icon icon={icon} /><b>{nameBold}</b></span>
                            <Button label={buttonLabel} onClick={buttonOnClick} />
                        </header>
                        <div className="content">
                            {children}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
            <footer>
                <Pagination
                    totalPages={pagination.total_pages}
                    gotoPage={onPaginationChange}
                />
            </footer>
        </div>
    );
};

export default DropArea;
