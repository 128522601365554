import { useEffect } from "react";
import Button from "../Button/Button";
import Select from "../../form/Select/Select";
import InputForm from "../../form/InputForm/InputForm";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "../Modal/Modal";

const ingredientSchema = yup.object().shape({
    ingredient: yup.object().required("Ingredient is required"),
    amount: yup.number().required("Amount is required").positive("Amount must be positive").integer("Amount must be an integer"),
});

const ModalIngredient = ({ onClose, onConfirm, name, item, options, initialIngredient }) => {
    const {setValues, setFieldValue, values, handleSubmit, errors, touched, handleChange} = useFormik({
        initialValues: {
            ingredient: null,
            amount: ""
        },
        validationSchema: ingredientSchema,
        onSubmit: (values) => {
            const ingredientData = {
                ingredient_id: values.ingredient?.id,
                amount: values.amount,
            };
            onConfirm(ingredientData);
        },
    });

    useEffect(() => {
        if (initialIngredient && initialIngredient.name) {
            setValues({
                ingredient: { name: initialIngredient.name, id: initialIngredient.ingredient_id },
                amount: initialIngredient.amount
            });
        }
    }, [initialIngredient]);

    return (
        <Modal
            onClose={onClose}
            name={name}
            item={item}
        >
            <form onSubmit={handleSubmit}>
                    <Select
                        options={options}
                        id="ingredient"
                        name="ingredient"
                        onChange={(option) => setFieldValue('ingredient', option)}
                        value={values.ingredient}
                        label="Ingredient"
                        variant="primary"
                        required
                        errorMessage={errors.ingredient}
                        touched={touched.ingredient}
                    />
                    <InputForm
                        id="amount"
                        name="amount"
                        required
                        label="Amount"
                        errorMessage={errors.amount}
                        touched={touched.amount}
                        value={values.amount || ''}
                        onChange={handleChange}
                        type="number"
                    />

                <div className="actions">
                    <Button type="button" onClick={onClose} label="Cancel" variant="cancel" />
                    <Button type="submit" label="Save" />
                </div>
            </form>
        </Modal>
    );
};

export default ModalIngredient;
