import Modal from "../Modal/Modal";
import Button from "../Button/Button";

const DeleteModal = ({ onClose, onConfirm, item, name }) => {
    return (
        <Modal
            onClose={onClose}
            name={name}
            item={item}
            buttons={
                <>
                    <Button onClick={onClose} label="Cancel" variant="cancel" />
                    <Button onClick={onConfirm} label="Delete" />
                </>
            }
        >
            <span>
                Are you sure that you want to delete: <b>{item}</b>?
            </span>
        </Modal>
    );
};
export default DeleteModal;
