import { useEffect, useState, useMemo } from "react";
import * as actions from './ArticlesActions';
import {Link, useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/icons/default_avatar_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as ArticleIcon } from "../../assets/icons/article_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import dayjs from 'dayjs';
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import { useSorting } from "../../hooks/useSorting";
import { usePagination } from "../../hooks/usePagination";

const Articles = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const initialFilters = useMemo(() => ({
        ...(location.state?.category_id ? { category_id: location.state.category_id } : {}),
    }), [location.state]);

    const [articles, setArticles] = useState([]);

    const [articleToDestroy, setArticleToDestroy] = useState(null);


    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, pagination, onPaginationChange, updatePagination, perPage } = usePagination();

    useEffect(() => {
        reload();
    }, [sorting, page]);

    const reload = () => {
        const params = {
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        };
        actions.loadArticles(params, data => {
            setArticles(data.articles);
            updatePagination(data.pagination);
        });
    };

    const handleDelete = () => {
        if (articleToDestroy) {
            actions.deleteArticle(articleToDestroy.id, () => {
                setArticles(prevArticles => prevArticles.filter(article => article.id !== articleToDestroy.id));
                setArticleToDestroy(null);
            });
        }
    };

    const handleAdd = () => {
        navigate('/article/');
    };

    const columns = useMemo(() => [
        {
            Header: 'Article',
            accessor: 'name',
            Cell: ({ row }) => {
                const imageUrl = row.original.image?.url;
                return (
                    <div className="cell">
                        {imageUrl ? <img src={imageUrl} alt={row.original.name} /> : <Icon icon={IconAvatar} />}
                        <span>{row.original.name}</span>
                    </div>
                );
            },
        },
        {
            Header: 'Published',
            accessor: 'created_at',
            Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/article/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => setArticleToDestroy({ id: row.original.id, name: row.original.name })}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        }
    ], []);

    return (
        <div className="articles">
            <Table
                nameBold="Articles"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={articles}
                icon={ArticleIcon}
                isFilter
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {articleToDestroy && (
                <DeleteModal
                    onClose={() => setArticleToDestroy(null)}
                    onConfirm={handleDelete}
                    name="Articles > "
                    item={articleToDestroy.name}
                />
            )}
        </div>
    );
};

export default Articles;
