import _ from 'lodash';
import Select from "../../form/Select/Select";

const RecipeFilter = ({ data, placeholder, isMulti, filterKey, setFilters, filters }) => {
    const uniqueData = data && _.uniqBy(data.flat(), 'name');

    const prepTimeOptions = [
        { name: 'Under 10 min', id: 0 },
        { name: '10-30 min', id: 10 },
        { name: '30-60 min', id: 30 },
        { name: '60-90 min', id: 60 },
        { name: 'Over 90 min', id: 90 },
    ];

    const options = uniqueData || prepTimeOptions;

    const handleChange = (selectedOptions) => {
        let selectedValues;

        if (isMulti) {
            selectedValues = selectedOptions.map(option => option.id);
        } else {
            selectedValues = selectedOptions.id;
        }

        setFilters(prevFilters => {
            if (filterKey === 'prep_time_min') {
                const minMax = getPrepTimeRange(selectedValues);
                return {
                    ...prevFilters,
                    prep_time_min: minMax.min,
                    prep_time_max: minMax.max
                };
            }
            return {
                ...prevFilters,
                [filterKey]: selectedValues
            };
        });
    };

    const getPrepTimeRange = (selectedId) => {
        const option = prepTimeOptions.find(opt => opt.id === selectedId);
        if (!option) return { min: null, max: null };

        switch (option.id) {
            case 0: return { min: 0, max: 10 };
            case 10: return { min: 10, max: 30 };
            case 30: return { min: 30, max: 60 };
            case 60: return { min: 60, max: 90 };
            case 90: return { min: 90, max: null };
            default: return { min: null, max: null };
        }
    };

    const getSelectedValue = () => {
        if (isMulti) {
            return options.filter(option => filters[filterKey]?.includes(option.id));
        } else {
            const selectedOption = options.find(option => option.id === filters[filterKey]);
            return selectedOption && { name: selectedOption.name };
        }
    };

    return (
        <span className="recipe-filter">
            <Select
                options={options}
                onChange={handleChange}
                value={getSelectedValue()}
                placeholder={placeholder}
                variant="filter"
                isMulti={isMulti}
            />
        </span>
    );
};

export default RecipeFilter;
