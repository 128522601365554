import {ReactComponent as PrepTimeIcon} from "../../../assets/icons/prep_time_icon.svg";
import {ReactComponent as HashtagIcon} from "../../../assets/icons/hashtag_icon.svg";
import {ReactComponent as UndefinedIcon} from "../../../assets/icons/undefined_image_icon.svg";
import Icon from "../../../assets/Icon"
import "./Card.scss"
import classNames from "classnames";

const Card = ({name, prepTime, tags, imageUrl, className, onClick}) => {
    return (
        <div className={classNames("card", className)}>
            <div className="body" onClick={onClick}>
                <div className="photo">
                    {imageUrl ? <img src={imageUrl}/> : <Icon icon={UndefinedIcon}/>}
                </div>
                <div className="text-area">
                    <h3>{name}</h3>
                    {prepTime && <span className="prep-time"><Icon icon={PrepTimeIcon}/> {prepTime} min</span>}
                    {tags && <span className="tags"><Icon icon={HashtagIcon}/>{tags.map(tag => tag.name).join(" ")}</span>}
                </div>
            </div>
        </div>
    )
}

export default Card