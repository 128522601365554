import { useState } from 'react';

export const usePagination = (initialPerPage = 10, initialPage = 1) => {
    const [page, setPage] = useState(initialPage);
    const [perPage, setPerPage] = useState(initialPerPage);
    const [pagination, setPagination] = useState({});

    const updatePagination = (newPagination) => {
        setPagination(newPagination);

        if (newPagination.perPage) {
            setPerPage(newPagination.perPage);
        }
    };

    const onPaginationChange = (pageNumber) => {
        setPage(pageNumber);
    };
    return {
        page,
        perPage,
        pagination,
        updatePagination,
        onPaginationChange,
    };
};
