import { components } from "react-select";
import "./Option.scss"

const Option = ({ isSelected, label, ...rest }) => {
    return (
        <div className="option">
            <components.Option {...rest}>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => null}
                />
                <label>
                    {label}
                    <span className="checkmark"></span>
                </label>
            </components.Option>
        </div>
    );
};

export default Option;
