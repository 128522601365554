import { Cell, Pie, PieChart, Legend } from "recharts";
import "./RecipesPieChart.scss";
import { useNavigate } from "react-router-dom";

const RecipesPieChart = ({ data, totalValue, colors }) => {
    const RADIAN = Math.PI / 180;
    const navigate = useNavigate();

    const handleSegmentClick = (categoryId) => {
        navigate('/recipes', { state: { category_id: categoryId } });
    };

    const renderCustomizedLabel = ({
                                       cx,
                                       cy,
                                       midAngle,
                                       innerRadius,
                                       outerRadius,
                                       value,
                                       index,
                                   }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                onClick={() => handleSegmentClick(data[index].id)}
            >
                {value}
            </text>
        );
    };

    const renderLegend = ({ payload }) => {
        return (
            <ul className="legend">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`}>
                        <span
                            className="circle"
                            style={{ backgroundColor: entry.color }}
                        />
                        {entry.value}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="recipes-pie-chart">
            <div className="header">
                <h1>{totalValue}</h1>
                <span>Recipes</span>
            </div>
            <PieChart width={250} height={450}>
                <Pie
                    data={data}
                    cx={120}
                    cy={200}
                    innerRadius={75}
                    outerRadius={120}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    dataKey="value"
                    onClick={({ payload }) => handleSegmentClick(payload.id)}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Legend
                    content={renderLegend}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
            </PieChart>
        </div>
    );
};

export default RecipesPieChart;
