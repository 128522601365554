import React, {useRef, useState} from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from "../../components/form/InputForm/InputForm";
import { useFormik } from "formik";
import UploadForm from "../../components/form/UploadFrom/UploadForm";
import Checkbox from "../../components/form/Checkbox/Checkbox";
import * as actions from "./UsersActions";
import * as yup from "yup";
import { ReactComponent as UserIcon } from "../../assets/icons/users_icon.svg";
import Form from "../../components/ux/Form/Form";
import Select from "../../components/form/Select/Select";
import {pick} from "lodash";

const userSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    role: yup.object().required("Role is required"),
    email: yup.string().email().required("Email is required"),
    image: yup.object().nullable()
        .test("fileSize", "The file is too large. Max size is 30MB.", value => {
            if (value && value.file) {
                const maxSize = 30 * 1024 * 1024;
                return value.file.size <= maxSize;
            }
            return true;
        })
        .test("fileType", "Unsupported file format. Please upload an image.", value => {
            if (value && value.file) {
                const supportedFormats = ["image/jpeg", "image/png"];
                return supportedFormats.includes(value.file.type);
            }
            return true;
        }),
    password: yup.string().when(['requirePassword', 'id'], {
        is: (requirePassword, id) => requirePassword || !id,
        then: () => yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters long"),
    }),
    passwordConfirm: yup.string().when(['requirePassword', 'id'], {
        is: (requirePassword, id) => requirePassword || !id,
        then: () => yup.string()
            .required("Password confirmation is required")
            .oneOf([yup.ref('password')], "Passwords must match"),
    }),
});

const options = [
    { id: 'ADMIN', name: 'Admin' },
    { id: 'USER', name: 'User' },
];

const User = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialNameRef = useRef("");

    const currentUser = JSON.parse(localStorage.getItem("USER_PROFILE"))

    const { values, errors, handleChange, handleSubmit, setValues, setFieldValue, touched, setErrors } = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            image: null,
            role: "",
            password: "",
            passwordConfirm: "",
            requirePassword: false
        },
        validationSchema: userSchema,
        onSubmit: (values) => {
            let imageId = values.image && values.image.id;

            const saveUser = () => {
                const userData = {user: {
                        ...pick(values, ['name', 'surname', 'email']),
                        password: values.password !== "" ? values.password : undefined,
                        role: values.role.id,
                        image_id: imageId
                    }
                };

                if (values.requirePassword) {
                    userData.password = values.password;
                }

                actions.saveUser(id, userData,
                    (success, error) => {
                    if (success) navigate("/users")
                    if (error) setErrors({email: error})
                });
            };

            if (values.image && values.image.file) {
                const imageData = new FormData();
                imageData.append("image[image]", values.image.file);

                actions.saveImage(imageData, (returnedId) => {
                    imageId = returnedId;
                    saveUser();
                })
            } else {
                saveUser();
            }
        },
    });

    const handleFileChange = (file) => {
        const image = {
            url: URL.createObjectURL(file),
            filename: file.name,
            file: file,
        };
        setFieldValue("image", image);
    };

    const handleDeleteImage = () => {
        setFieldValue("image", null);
    };

    useEffect(() => {
        if (id) {
            actions.loadUser(id, user => {
                const userRole = options.find(option => option.id === user.role);
                setValues({...user, password: "", passwordConfirm: "", role: {id: userRole.id, name: userRole.name}});
                initialNameRef.current = user.name;
            });
        }
    }, [id, setValues]);

    const handleCheckboxChange = (e) => {
        setFieldValue('requirePassword', e.target.checked);
    };

    return (
        <Form
            name="Users > "
            nameBold={id ? initialNameRef.current : "New"}
            buttonLabel="Save"
            buttonType="submit"
            icon={UserIcon}
            onSubmit={handleSubmit}
        >
            <InputForm
                id="name"
                required
                label="Name"
                errorMessage={errors.name}
                touched={touched.name}
                value={values.name}
                onChange={handleChange}
            />
            <InputForm
                id="surname"
                label="Surname"
                errorMessage={errors.surname}
                touched={touched.surname}
                value={values.surname || ""}
                onChange={handleChange}
            />
            <InputForm
                id="email"
                required
                label="Email"
                errorMessage={errors.email}
                touched={touched.email}
                value={values.email}
                onChange={handleChange}
            />
            {!id &&
                <>
                    <InputForm
                        id="password"
                        type="password"
                        required
                        label="Password"
                        errorMessage={errors.password}
                        touched={touched.password}
                        value={values.password}
                        onChange={handleChange}
                    />
                    <InputForm
                        id="passwordConfirm"
                        type="password"
                        required
                        label="Confirm Password"
                        errorMessage={errors.passwordConfirm}
                        touched={touched.passwordConfirm}
                        value={values.passwordConfirm}
                        onChange={handleChange}
                    />
                </>
            }
            {values.id !== currentUser.id &&
                <Select options={options}
                    id="role"
                    onChange={(role)=> setFieldValue("role", role)}
                    value={values.role}
                    label="Role"
                    variant="primary"
                    errorMessage={errors.role}
                    touched={touched.role}
                    required
            />}
            <UploadForm
                onFileChange={handleFileChange}
                errorMessage={errors.image}
                image={values.image}
                onDelete={handleDeleteImage}
            />
            {id && <Checkbox
                id="requirePassword"
                label="Change password"
                checked={values.requirePassword || false}
                onChange={handleCheckboxChange}
            /> }
            {values.requirePassword && (
                <>
                    <InputForm
                        id="password"
                        type="password"
                        required
                        label="Password"
                        errorMessage={errors.password}
                        touched={touched.password}
                        value={values.password}
                        onChange={handleChange}
                    />
                    <InputForm
                        id="passwordConfirm"
                        type="password"
                        required
                        label="Confirm Password"
                        errorMessage={errors.passwordConfirm}
                        touched={touched.passwordConfirm}
                        value={values.passwordConfirm}
                        onChange={handleChange}
                    />
                </>
            )}
        </Form>
    );
};

export default User;

