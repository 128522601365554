import apiClient from '../../utils/apiClient';

export const loadRecipes = (filters, callback) => {
    apiClient.get('/recipes', { params: filters })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};
export const loadRecipe = (id, callback) => {
    apiClient.get(`/recipes/${id}`)
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const deleteRecipe = (id, callback) => {
    apiClient.delete(`/recipes/${id}`)
        .then(() => callback())
        .catch(error => console.error(error));
};

export const saveRecipe = (id, postData, callback) => {
    const user = JSON.parse(localStorage.getItem("USER_PROFILE"));
    const url = id ? `/recipes/${id}` : `/recipes`;
    const method = id ? 'PUT' : 'POST';

    apiClient({
        url,
        method,
        data: { ...postData, user_id: user.id }
    })
        .then(response => callback(response))
        .catch(error => console.error(error));
};

export const saveImage = (postData, callback) => {
    apiClient.post('/images', postData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => callback(response.data.id))
        .catch(error => console.error(error));
};

export const loadCategories = (callback) => {
    apiClient.get('/categories')
        .then(response => callback(response.data.categories))
        .catch(error => console.error(error));
};

export const loadTags = (callback) => {
    apiClient.get('/tags')
        .then(response => callback(response.data.tags))
        .catch(error => console.error(error));
};

export const loadIngredients = (callback) => {
    apiClient.get('/ingredients')
        .then(response => callback(response.data.ingredients))
        .catch(error => console.error(error));
};

export const loadPublicRecipes = (params, callback) => {
    apiClient.get(`/recipes/public_index`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};

export const loadPublicRecipe = (params, callback) => {
    apiClient.get(`/recipes/public_show`, { params })
        .then(response => callback(response.data))
        .catch(error => console.error(error));
};