import axios from 'axios';
import appConfig from "../config/applicationConfiguration";

const apiClient = axios.create({
    baseURL: appConfig.apiUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        const isLoginRequest = error.config.url.includes('/users/sign_in');

        if (!isLoginRequest) {
            localStorage.removeItem("ACCESS_TOKEN");
            localStorage.removeItem("USER_PROFILE");
            window.location.href = "/";
        }
    }
    return Promise.reject(error);
});

export default apiClient;