import { useEffect, useState, useMemo } from "react";
import * as actions from './RecipesActions.js';
import {Link, useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as IconAvatar } from '../../assets/icons/default_avatar_icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash_icon.svg';
import Icon from '../../assets/Icon';
import { ReactComponent as RecipeIcon } from "../../assets/icons/recipe_icon.svg";
import Table from "../../components/ux/Table/Table.js";
import dayjs from 'dayjs';
import DeleteModal from "../../components/ux/ModalDelete/DeleteModal";
import RecipeFilter from "../../components/ux/RecipeFilter/RecipeFilter";
import { useSorting } from "../../hooks/useSorting";
import { usePagination } from "../../hooks/usePagination";

const Recipes = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const initialFilters = useMemo(() => ({
        ...(location.state?.category_id ? { category_id: location.state.category_id } : {}),
    }), [location.state]);

    const [recipes, setRecipes] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const [options, setOptions] = useState({
        categoryOption: [],
        tagOption: [],
        ingredientOption: []
    });
    const [itemToDestroy, setItemToDestroy] = useState(null);


    const { sorting, onSortingChange, field, order } = useSorting();
    const { page, pagination, onPaginationChange, updatePagination, perPage } = usePagination();

    useEffect(() => {
        reload();
    }, [filters, sorting, page]);

    useEffect(() => {
        actions.loadCategories((categories) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                categoryOption: categories,
            }));
        });

        actions.loadTags((tags) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                tagOption: tags,
            }));
        });

        actions.loadIngredients((ingredients) => {
            setOptions(prevOptions => ({
                ...prevOptions,
                ingredientOption: ingredients,
            }));
        });
    }, []);

    const reload = () => {
        const sortedFilters = {
            ...filters,
            page,
            per_page: perPage,
            sort_by: field,
            sort_order: order,
        };
        actions.loadRecipes(sortedFilters, data => {
            setRecipes(data.recipes);
            updatePagination(data.pagination);
        });
    };

    const handleDelete = () => {
        if (itemToDestroy) {
            actions.deleteRecipe(itemToDestroy.id, () => {
                setRecipes(prev => prev.filter(item => item.id !== itemToDestroy.id));
                setItemToDestroy(null);
            });
        }
    };

    const handleAdd = () => {
        navigate('/recipe/');
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        onPaginationChange(1);
    };

    const columns = useMemo(() => [
        {
            Header: 'Recipe',
            accessor: 'name',
            Cell: ({ row }) => {
                const imageUrl = row.original.image?.url;
                return (
                    <div className="cell">
                        {imageUrl ? <img src={imageUrl} alt={row.original.name} /> : <Icon icon={IconAvatar} />}
                        <span>{row.original.name}</span>
                    </div>
                );
            },
        },
        {
            Header: 'Published',
            accessor: 'created_at',
            Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            Header: 'Options',
            Cell: ({ row }) => (
                <>
                    <Link to={`/recipe/${row.original.id}`}>
                        <Icon icon={EditIcon} />
                    </Link>
                    <button onClick={() => setItemToDestroy({ id: row.original.id, name: row.original.name })}>
                        <Icon icon={TrashIcon} />
                    </button>
                </>
            )
        },
        {
            Header: "Category",
            accessor: "category.name",
            Filter: () => (
                <RecipeFilter
                    data={options.categoryOption}
                    placeholder="Categories"
                    isMulti={false}
                    filterKey="category_id"
                    setFilters={handleFilterChange}
                    filters={filters}
                />
            ),
        },
        {
            Header: "Ingredient",
            accessor: (recipe) => recipe.ingredients.map(ingredient => ingredient.name),
            Filter: () => (
                <RecipeFilter
                    data={options.ingredientOption}
                    placeholder="Ingredients"
                    isMulti
                    filterKey="ingredient_ids"
                    setFilters={handleFilterChange}
                    filters={filters}
                />
            ),
        },
        {
            Header: "Tag",
            accessor: (recipe) => recipe.tags.map(tag => tag.name),
            Filter: () => (
                <RecipeFilter
                    data={options.tagOption}
                    placeholder="Tags"
                    isMulti
                    filterKey="tag_ids"
                    setFilters={handleFilterChange}
                    filters={filters}
                />
            ),
        },
        {
            Header: "Preparation Time",
            accessor: "prep_time",
            Filter: () => (
                <RecipeFilter
                    placeholder="Preparation Time"
                    filterKey="prep_time_min"
                    setFilters={handleFilterChange}
                    filters={filters}
                />
            ),
        }
    ], [options, filters]);

    return (
        <div className="recipes">
            <Table
                nameBold="Recipes"
                buttonOnClick={handleAdd}
                buttonLabel="+ Add New"
                columns={columns}
                data={recipes}
                icon={RecipeIcon}
                isFilter
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
            />
            {itemToDestroy && (
                <DeleteModal
                    onClose={() => setItemToDestroy(null)}
                    onConfirm={handleDelete}
                    name="Recipes > "
                    item={itemToDestroy.name}
                />
            )}
        </div>
    );
};

export default Recipes;
