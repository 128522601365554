import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Login.scss';
import InputForm from "../../components/form/InputForm/InputForm";
import Button from "../../components/ux/Button/Button";
import * as actions from "./LoginActions";
import Alert from "../../components/ux/Alert/Alert";
import {useNavigate} from "react-router-dom";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email or username is required'),
    password: Yup.string()
        .required('Password is required'),
});

const Login = () => {
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            actions.login(values, (role, error) => {
                if (role) {
                    if (role === 'ADMIN') navigate('/users')
                    if (role === 'USER') navigate('/recipes')
                } else {
                    setAlertMessage(error);
                }
            });
        },
    });

    return (
        <div className="login">
            <div className="background" />
            <form onSubmit={formik.handleSubmit}>
                <h1>Nice to see you!</h1>
                {alertMessage && <Alert message={alertMessage} />}
                <span>Login</span>
                <InputForm
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    errorMessage={formik.errors.email}
                    touched={formik.touched.email}
                />
                <span>Password</span>
                <InputForm
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.password}
                    touched={formik.touched.password}
                />
                <Button type="submit" label="Login" />
            </form>
        </div>
    );
};

export default Login;
