import './Select.scss';
import SelectReact from 'react-select';
import {ReactComponent as profileIcon} from "../../../assets/icons/profile_icon.svg";
import Icon from "../../../assets/Icon";
import classNames from 'classnames';
import Option from "../Option/Option";

const errorStyles = {
    control: (provided) => ({
        ...provided,
        background: '#faecec',
        border: '1px solid #e56773',
        cursor: 'pointer'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#e56773',
        fontSize: '.9rem'
    }),
};

const menuStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '.6rem',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer'
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (provided) => ({ ...provided, color: '#000' }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '.6rem'
    }),
};

const primaryStyles = {
    multiValue: (provided) => ({
        ...provided,
        background: '#eae1ff',
        borderRadius: '.6rem',
        padding: '.1rem',
    }),
};

const filterStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.data.label === state.selectProps.value?.label ? `#ececec` : `transparent`,
        cursor: 'pointer',
        '&:hover': {
            color: '#8880eb',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    multiValue: (provided) => ({
        ...provided,
        background: '#eae1ff',
        borderRadius: '.6rem',
        padding: '.1rem',
    }),
}


const Select = ({
                    options,
                    onChange,
                    variant = "primary",
                    errorMessage,
                    touched,
                    value,
                    isMulti = false,
                    label,
                    required,
                    placeholder,
                    profileImage,
                }) => {

    const getStyles = () => {
        if (errorMessage && touched) {
            return errorStyles;
        }
        if (variant === "menu" || variant === 'profile') {
            return menuStyles;
        }
        if (variant === "filter") {
            return filterStyles;
        }
        return primaryStyles;
    }

    const mapIdNameToValueLabel = (item) => ({ value: item.id, label: item.name });
    const mapValueLabelToIdName = (item) => ({ id: item.value, name: item.label });
    const formattedOptions = options.map(mapIdNameToValueLabel);

    let formattedValue;
    if (isMulti) {
        formattedValue = value.map(mapIdNameToValueLabel);
    } else {
        formattedValue = value ? mapIdNameToValueLabel(value) : null;
    }

    const handleChange = (selectedOption) => {
        if (isMulti) {
            const selectedValues = selectedOption ? selectedOption.map(mapValueLabelToIdName) : [];
            onChange(selectedValues);
        } else {
            const selectedValue = selectedOption ? mapValueLabelToIdName(selectedOption) : null;
            onChange(selectedValue);
        }
    };

    return (
        <div className={classNames('select', variant)}>
            {variant !== 'menu' && (
                <>
                    {required && <span className="required">*</span>}
                    <label>
                        {variant === 'profile' && (
                            profileImage ? (
                                <img src={profileImage} alt="Profile" />
                            ) : (
                                <Icon icon={profileIcon} />
                            )
                        )}
                        {label}
                    </label>
                </>
            )}
            <SelectReact
                options={formattedOptions}
                onChange={handleChange}
                value={formattedValue}
                placeholder={errorMessage && touched ? errorMessage : placeholder}
                className='input'
                styles={getStyles()}
                isMulti={isMulti}
                isSearchable={variant === 'primary'}
                components={isMulti && variant === 'filter' && { Option }}
                closeMenuOnSelect={!(isMulti && variant === 'filter')}
                hideSelectedOptions={!(isMulti && variant === 'filter')}
            />
        </div>
    );
};

export default Select;