import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash_icon.svg';
import { ReactComponent as PageIcon } from '../../../assets/icons/page_icon.svg';
import Icon from '../../../assets/Icon';
import "./DraggableItem.scss";

const DraggableItem = ({ id, name, index, setItemToDelete }) => {
    return (
        <Draggable draggableId={id.toString()} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="draggable-item"
                >
                    <span><Icon icon={PageIcon} /> {name}</span>
                    {name !== "Articles" && name !== "Recipes" &&
                        <div className="actions">
                            <Link to={`/subpage/${id}`}>
                                <Icon icon={EditIcon} />
                            </Link>
                            <button onClick={() => setItemToDelete({ id, name })}>
                                <Icon icon={TrashIcon} />
                            </button>
                        </div>
                    }
                </div>
            )}
        </Draggable>
    );
};

export default DraggableItem;
